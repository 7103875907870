export enum NursingSpecializations {
  MedSurg = 'Med-Surg',
  IntensiveCare = 'Intensive Care',
  TelemetryStepdown = 'Telemetry/Step-down',
  EmergencyRoom = 'Emergency Room',
  Surgical = 'Surgical',
  Endoscopy = 'Endoscopy',
  InterventionalRadiology = 'Interventional Radiology',
  LaborAndDelivery = 'Labor and Delivery',
  Pediatrics = 'Pediatrics',
  Other = 'Other',
}
