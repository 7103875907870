import { FormServiceAddSubmissionEvent } from '../../events/FormServiceAddSubmissionEvent'
import { FormDTO } from '../../models/FormDTO.model'
import { FormFieldDTO } from '../../models/FormFieldDTO.model'
import { FormFieldRelations } from '../../models/FormFieldRelations.model'
import { FormRelations } from '../../models/FormRelations.model'

import ScrubstrApiQueryService, {
  ScrubstrApiQueryServiceGetEvent,
} from '../ScrubstrApiQueryService/ScrubstrApiQueryService'

export class FormService {
  /**
   * @description Fetches a form by id
   *
   * @param id
   *
   * @returns promise for a Form
   */
  public async get({ id, relations }: { id: number; relations?: FormRelations[] }): Promise<FormDTO> {
    const params = {
      relations: relations ? relations.join(',') : undefined,
    }
    const scrubstrApiQueryRequest: ScrubstrApiQueryServiceGetEvent = {
      endpoint: `form/${id}`,
      params,
    }

    const scrubstrApiQueryService = new ScrubstrApiQueryService()
    const formRaw = await scrubstrApiQueryService.get<FormDTO>(scrubstrApiQueryRequest)
    const form: FormDTO = {
      id: formRaw.id,
      name: formRaw.name,
      submitAction: formRaw.submitAction,
      updatedAt: formRaw.updatedAt,
      createdAt: formRaw.createdAt,
    }
    if (formRaw.pages) {
      form.pages = formRaw.pages.map((page) => {
        if (page.fields) {
          // sort the fields by the order property and then the id property and then set the fields property on the form object
          page.fields = page.fields.sort((a, b) => {
            if (a.order === b.order) {
              return a.id - b.id
            }
            return a.order - b.order
          })
        }
        return page
      })
    }
    if (formRaw.fields) {
      // sort the fields by the order property and then the id property and then set the fields property on the form object
      form.fields = formRaw.fields.sort((a, b) => {
        if (a.order === b.order) {
          return a.id - b.id
        }
        return a.order - b.order
      })
    }
    if (formRaw.submissions) {
      form.submissions = formRaw.submissions
    }
    return form
  }

  /**
   * @description Fetches a form field by id
   *
   * @param id
   *
   * @returns promise for a Form
   */
  public async getField({ id, relations }: { id: number; relations?: FormFieldRelations[] }): Promise<FormFieldDTO> {
    const params = {
      relations: relations ? relations.join(',') : undefined,
    }
    const scrubstrApiQueryRequest: ScrubstrApiQueryServiceGetEvent = {
      endpoint: `form-field/${id}`,
      params,
    }

    const scrubstrApiQueryService = new ScrubstrApiQueryService()
    const formFieldRaw = await scrubstrApiQueryService.get<FormFieldDTO>(scrubstrApiQueryRequest)
    const formField: FormFieldDTO = {
      id: formFieldRaw.id,
      type: formFieldRaw.type,
      label: formFieldRaw.label,
      order: formFieldRaw.order,
      inputName: formFieldRaw.inputName,
      defaultValue: formFieldRaw.defaultValue,
      isRating: formFieldRaw.isRating,
      isRequired: formFieldRaw.isRequired,
      visible: formFieldRaw.visible,
      placeholder: formFieldRaw.placeholder,
      choices: formFieldRaw.choices,
      updatedAt: formFieldRaw.updatedAt,
      createdAt: formFieldRaw.createdAt,
    }
    if (formFieldRaw.form) {
      formField.form = formFieldRaw.form
    }
    if (formFieldRaw.page) {
      formField.page = formFieldRaw.page
    }
    if (formFieldRaw.answers) {
      formField.answers = formFieldRaw.answers
    }
    return formField
  }

  /**
   * @description Adds a new form submission
   *
   * @param formSubmission
   *
   * @returns promise void
   */
  public async addSubmission(formSubmission: FormServiceAddSubmissionEvent): Promise<void> {
    const scrubstrApiQueryService = new ScrubstrApiQueryService()
    await scrubstrApiQueryService.post({
      endpoint: 'form-submission',
      data: formSubmission,
    })
  }
}
