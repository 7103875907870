import axios, { CancelTokenSource } from 'axios'
import { getObjectKeyFromValue } from '../../utils'

import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { Hospital } from '../../models/Hospital.model'
import { HospitalDTO } from '../../models/HospitalDTO.model'
import { USStates } from '../../models/USStates.model'

import ScrubstrApiQueryService, {
  ScrubstrApiQueryServiceGetEvent,
} from '../ScrubstrApiQueryService/ScrubstrApiQueryService'

export class HospitalService {
  private autocompleteHospitalsSource: CancelTokenSource | null = null

  /**
   * @description Search for a single hospital by id or permalink
   *
   * @param id
   * @param permalink
   *
   * @returns promise for a Hospital
   */
  public async searchOne({ id, permalink }: { id?: number; permalink?: string }): Promise<Hospital> {
    const params = {
      id,
      permalink,
    }
    const scrubstrApiQueryRequest: ScrubstrApiQueryServiceGetEvent = {
      endpoint: `hospital/search-one`,
      params,
    }

    const scrubstrApiQueryService = new ScrubstrApiQueryService()
    const hospitalRaw = await scrubstrApiQueryService.get<HospitalDTO>(scrubstrApiQueryRequest)
    const hospital: Hospital = {
      id: hospitalRaw.id,
      image: hospitalRaw.imageURL,
      name: hospitalRaw.name,
      address: hospitalRaw.address,
      city: hospitalRaw.city,
      state: {
        name: getObjectKeyFromValue(USStates, hospitalRaw.state),
        code: hospitalRaw.state,
      },
      zip: hospitalRaw.zipcode,
      rating: hospitalRaw.averageRating,
      permalink: hospitalRaw.permalink,
      reviews: hospitalRaw.reviews,
      reviewCount: hospitalRaw.reviewCount,
      ratingsSummary: hospitalRaw.ratingsSummary,
      mostCommonScrubColorAnswer: hospitalRaw.mostCommonScrubColorAnswer,
      mostCommonChartingSoftwareAnswer: hospitalRaw.mostCommonChartingSoftwareAnswer,
      updatedAt: hospitalRaw.updatedAt,
      createdAt: hospitalRaw.createdAt,
    }
    return hospital
  }

  public async search({
    page,
    pageSize,
    term,
    state,
    city,
    sort,
    autocomplete,
  }: {
    page?: number
    pageSize?: number
    term: string
    state?: USStates
    city?: string
    sort: string
    autocomplete?: boolean
  }): Promise<{
    hospitals: Hospital[]
    totalResults: number
    totalPages: number
    currentPage: number
  }> {
    const params: {
      page?: number
      pageSize?: number
      name?: string
      state?: USStates
      city?: string
      orderBy?: string
      orderDirection?: string
      orderAToZTies?: string
      excludeReviews?: string
    } = {
      page,
      pageSize,
      name: term || undefined,
      state,
      city,
      excludeReviews: autocomplete ? 'true' : undefined,
    }

    if (sort) {
      switch (sort) {
        case 'highRating':
          params.orderBy = 'averageRating'
          params.orderDirection = 'DESC'
          params.orderAToZTies = 'true'
          break
        case 'lowRating':
          params.orderBy = 'averageRating'
          params.orderDirection = 'ASC'
          params.orderAToZTies = 'true'
          break
        case 'az':
          params.orderBy = 'name'
          params.orderDirection = 'ASC'
          break
        case 'za':
          params.orderBy = 'name'
          params.orderDirection = 'DESC'
          break
        default:
          break
      }
    }

    const scrubstrApiQueryRequest: ScrubstrApiQueryServiceGetEvent = {
      endpoint: 'hospital',
      params,
    }

    if (autocomplete) {
      if (this.autocompleteHospitalsSource !== null) {
        this.autocompleteHospitalsSource.cancel('cancelToken triggered')
      }
      this.autocompleteHospitalsSource = axios.CancelToken.source()
      scrubstrApiQueryRequest.cancelToken = this.autocompleteHospitalsSource.token
    }

    const hospitalResults: {
      hospitals: Hospital[]
      totalResults: number
      totalPages: number
      currentPage: number
    } = {
      hospitals: [],
      totalResults: 0,
      totalPages: 0,
      currentPage: Number(page),
    }
    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      const hospitalsRaw = await scrubstrApiQueryService.get<PaginatedResponse<HospitalDTO>>(scrubstrApiQueryRequest)
      // wrap to avoid errors if the request is cancelled
      if (hospitalsRaw) {
        hospitalResults.hospitals = hospitalsRaw.data.map((hospital) => ({
          id: hospital.id,
          image: hospital.imageURL,
          name: hospital.name,
          address: hospital.address,
          city: hospital.city,
          zip: hospital.zipcode,
          state: {
            name: getObjectKeyFromValue(USStates, hospital.state),
            code: hospital.state,
          },
          rating: hospital.averageRating,
          reviewCount: hospital.reviewCount,
          permalink: hospital.permalink,
          updatedAt: hospital.updatedAt,
          createdAt: hospital.createdAt,
        }))

        hospitalResults.totalResults = hospitalsRaw.total
        hospitalResults.totalPages = Math.ceil(hospitalsRaw.total / 12)
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return hospitalResults
  }

  public async searchCityState(params: { searchTerm: string; limit?: number }) {
    const scrubstrApiQueryRequest: ScrubstrApiQueryServiceGetEvent = {
      endpoint: 'hospital/search-city-state',
      params,
    }

    if (this.autocompleteHospitalsSource !== null) {
      this.autocompleteHospitalsSource.cancel('cancelToken triggered')
    }
    this.autocompleteHospitalsSource = axios.CancelToken.source()
    scrubstrApiQueryRequest.cancelToken = this.autocompleteHospitalsSource.token

    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      return await scrubstrApiQueryService.get<{ city: string; state: USStates; stateName: string }[]>(
        scrubstrApiQueryRequest,
      )
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }
}
