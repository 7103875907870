import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { checkIsUpTree } from '../utils'

import { UserDTO } from '../models/UserDTO.model'

import UserService from '../services/UserService/UserService'

import { LoginButton } from './LoginButton'
import ScrollToTop from './scrollToTop'
import { RegisterButton } from './RegisterButton'

export const TopNav = (): JSX.Element => {
  const { user, isAuthenticated, isLoading } = useAuth0()
  const [userDetails, setUserDetails] = useState<UserDTO>()
  const [mobileMenuIsOpen, setMobileMenuOpen] = useState(false)
  const [dtMenuReviewIsOpen, setDtMenuReviewIsOpen] = useState(false)
  const [dtMenuToolsIsOpen, setDtMenuToolsIsOpen] = useState(false)

  const dtMenuToolsContainerRef = useRef<HTMLUListElement>(null)
  const dtMenuReviewContainerRef = useRef<HTMLUListElement>(null)
  const mobileMenuContainerRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    // format the user data
    if (isAuthenticated && user) {
      setUserDetails(UserService.formatUser(user))
    }
  }, [isAuthenticated, user])

  return (
    <div className='flex align-middle'>
      {!isLoading && (
        <div className='self-center z-50'>
          <ScrollToTop />
          {/* Mobile menu */}
          <nav className='lg:hidden'>
            <span className='flex gap-2 align-middle'>
              <li className='relative flex justify-center'>
                {isAuthenticated && userDetails ? (
                  <Link to='/profile' className='flex items-center'>
                    {userDetails.picture ? (
                      <img
                        className='max-h-12 max-w-12 rounded-full p-2 cursor-pointer'
                        src={userDetails.picture}
                        alt='profile avatar'
                        referrerPolicy='no-referrer'
                      />
                    ) : (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-12 w-12 p-2'
                        style={{ color: 'rgb(48, 60, 108)' }}
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth={2}
                          d='M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z'
                        />
                      </svg>
                    )}
                  </Link>
                ) : (
                  <LoginButton className='bg-orange py-2 px-4 text-white rounded' />
                )}
              </li>
              <button
                onClick={() => {
                  setMobileMenuOpen(!mobileMenuIsOpen)
                }}
                onBlur={(evt) => {
                  if (!checkIsUpTree(evt.relatedTarget, mobileMenuContainerRef.current)) {
                    setMobileMenuOpen(false)
                  }
                }}>
                <svg xmlns='http://www.w3.org/2000/svg' height='24' width='24'>
                  <path d='M12 20q-.825 0-1.412-.587Q10 18.825 10 18q0-.825.588-1.413Q11.175 16 12 16t1.413.587Q14 17.175 14 18q0 .825-.587 1.413Q12.825 20 12 20Zm0-6q-.825 0-1.412-.588Q10 12.825 10 12t.588-1.413Q11.175 10 12 10t1.413.587Q14 11.175 14 12q0 .825-.587 1.412Q12.825 14 12 14Zm0-6q-.825 0-1.412-.588Q10 6.825 10 6t.588-1.412Q11.175 4 12 4t1.413.588Q14 5.175 14 6t-.587 1.412Q12.825 8 12 8Z' />
                </svg>
              </button>
            </span>
            {mobileMenuIsOpen && (
              <ul
                ref={mobileMenuContainerRef}
                className='absolute left-0 right-0 bg-white shadow m-4 p-2 border-t-4 border-solid border-blue-400 overflow-y-auto z-50'>
                <li className='px-2 pt-4'>
                  <NavItem
                    className='block py-2 px-4 border-b-2 border-solid border-gray-200'
                    style={{ background: '#00000008' }}>
                    Reviews
                  </NavItem>
                  <ul className='ml-8'>
                    <li className='border-b-2 border-solid border-gray-200'>
                      <Link to={'/hospitals/'}>
                        <button className='py-2 px-4 w-full text-left'>
                          <NavItem>Hospitals</NavItem>
                        </button>
                      </Link>
                    </li>
                    <li>
                      <Link to={'/agencies/'}>
                        <button className='py-2 px-4 w-full text-left'>
                          <NavItem>Agencies</NavItem>
                        </button>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className='px-2'>
                  <NavItem
                    className='block py-2 px-4 border-b-2 border-solid border-gray-200'
                    style={{ background: '#00000008' }}>
                    Tools
                  </NavItem>
                  <ul className='ml-8'>
                    <li className='border-b-2 border-solid border-gray-200'>
                      <Link to={'/income-calculator/'}>
                        <button className='py-2 px-4 w-full text-left'>
                          <NavItem>Income Calculator</NavItem>
                        </button>
                      </Link>
                    </li>
                    <li>
                      <Link to={'/gsa-calculator/'}>
                        <button className='py-2 px-4 w-full text-left'>
                          <NavItem>GSA Calculator</NavItem>
                        </button>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className='px-2'>
                  <NavItem
                    className='block py-2 px-4 border-b-2 border-solid border-gray-200'
                    style={{ background: '#00000008' }}>
                    Profile
                  </NavItem>
                  <ul className='ml-8'>
                    <li className='border-b-2 border-solid border-gray-200'>
                      {isAuthenticated && userDetails ? (
                        <Link to={'/profile'}>
                          <button className='py-2 px-4 w-full text-left'>
                            <NavItem>My Profile</NavItem>
                          </button>
                        </Link>
                      ) : (
                        <LoginButton className='py-2 px-4 w-full text-left'>
                          <NavItem>Login</NavItem>
                        </LoginButton>
                      )}
                    </li>
                    {(!isAuthenticated || !userDetails) && (
                      <li>
                        <RegisterButton className='py-2 px-4 w-full text-left'>
                          <NavItem>Register</NavItem>
                        </RegisterButton>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            )}
          </nav>
          {/* Desktop Menu */}
          <nav className='hidden lg:inline-block'>
            <ul className='flex gap-4'>
              <li className='relative self-center'>
                <button
                  className='flex py-2 px-4'
                  onClick={() => {
                    setDtMenuReviewIsOpen(!dtMenuReviewIsOpen)
                  }}
                  onBlur={(evt) => {
                    if (!checkIsUpTree(evt.relatedTarget, dtMenuReviewContainerRef.current)) {
                      setDtMenuReviewIsOpen(false)
                    }
                  }}>
                  <NavItem>Reviews</NavItem>
                  <svg className='self-center' xmlns='http://www.w3.org/2000/svg' height='20' width='20'>
                    <path d='m10 13.062-5-5L6.062 7 10 10.938 13.938 7 15 8.062Z' />
                  </svg>
                </button>
                {dtMenuReviewIsOpen && (
                  <ul
                    className='bg-white absolute left-auto right-0 shadow border-t-2 border-solid border-blue-400 z-50'
                    style={{ top: 'calc(100% - 1px)', width: '240px' }}
                    ref={dtMenuReviewContainerRef}>
                    <li className='m-2'>
                      <Link to={'/hospitals/'}>
                        <button className='py-2 px-4 w-full text-left bg-opacity-5 hover:bg-gray-100 transition-all duration-300'>
                          <NavItem aria-current='page'>Hospitals</NavItem>
                        </button>
                      </Link>
                    </li>
                    <li className='m-2'>
                      <Link to={'/agencies/'}>
                        <button className='py-2 px-4 w-full text-left bg-opacity-5 hover:bg-gray-100 transition-all duration-300'>
                          <NavItem aria-current='page'>Agencies</NavItem>
                        </button>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li className='relative self-center'>
                <button
                  className='flex py-2 px-4'
                  onClick={() => {
                    setDtMenuToolsIsOpen(!dtMenuToolsIsOpen)
                  }}
                  onBlur={(evt) => {
                    if (!checkIsUpTree(evt.relatedTarget, dtMenuToolsContainerRef.current)) {
                      setDtMenuToolsIsOpen(false)
                    }
                  }}>
                  <NavItem>Tools</NavItem>
                  <svg className='self-center' xmlns='http://www.w3.org/2000/svg' height='20' width='20'>
                    <path d='m10 13.062-5-5L6.062 7 10 10.938 13.938 7 15 8.062Z' />
                  </svg>
                </button>
                {dtMenuToolsIsOpen && (
                  <ul
                    className='bg-white absolute left-auto right-0 shadow border-t-2 border-solid border-blue-400 z-50'
                    style={{ top: 'calc(100% - 1px)', width: '240px' }}
                    ref={dtMenuToolsContainerRef}>
                    <li className='m-2'>
                      <Link to={'/income-calculator/'}>
                        <button className='py-2 px-4 w-full text-left bg-opacity-5 hover:bg-gray-100 transition-all duration-300'>
                          <NavItem aria-current='page'>Income Calculator</NavItem>
                        </button>
                      </Link>
                    </li>
                    <li className='m-2'>
                      <Link to={'/gsa-calculator/'}>
                        <button className='py-2 px-4 w-full text-left bg-opacity-5 hover:bg-gray-100 transition-all duration-300'>
                          <NavItem aria-current='page'>GSA Calculator</NavItem>
                        </button>
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li className='relative flex justify-center'>
                {isAuthenticated && userDetails ? (
                  <Link to='/profile' className='flex items-center'>
                    {userDetails.picture ? (
                      <img
                        className='max-h-12 max-w-12 rounded-full p-2 cursor-pointer'
                        src={userDetails.picture}
                        alt='profile avatar'
                        referrerPolicy='no-referrer'
                      />
                    ) : (
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-12 w-12 p-2'
                        style={{ color: 'rgb(48, 60, 108)' }}
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth={2}
                          d='M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z'
                        />
                      </svg>
                    )}
                  </Link>
                ) : (
                  <LoginButton className='p-2'>
                    <NavItem>Login</NavItem>
                  </LoginButton>
                )}
              </li>
              {!isAuthenticated && (
                <li className='relative p-2'>
                  <RegisterButton className='bg-orange py-2 px-4 text-white rounded'>
                    <NavItem>Register</NavItem>
                  </RegisterButton>
                </li>
              )}
            </ul>
          </nav>
        </div>
      )}
    </div>
  )
}

// NavItem component containing inner span used as label for the nav item
interface NavItemProps extends React.HTMLAttributes<HTMLBaseElement> {
  children?: ReactNode
}
export const NavItem = (props: NavItemProps): JSX.Element => {
  const { children, className, ...otherProps } = props

  return (
    <span {...otherProps} className={`font-open font-550 text-14 uppercase tracking-widest leading-3em ` + className}>
      {children}
    </span>
  )
}
