import { IncomeCalcDTO } from '../../models/IncomeCalcDTO.model'
import { USStates } from '../../models/USStates.model'

import ScrubstrApiQueryService, {
  ScrubstrApiQueryServiceGetEvent,
} from '../ScrubstrApiQueryService/ScrubstrApiQueryService'

export class IncomeCalcService {
  /**
   * @description Fetches GSA rates
   *
   * @param event
   *
   * @returns promise for GSA rates
   */
  public async get(event: { state: USStates; city: string; startDate: string }): Promise<IncomeCalcDTO> {
    const scrubstrApiQueryRequest: ScrubstrApiQueryServiceGetEvent = {
      endpoint: `income-calc/get-rates`,
      params: {
        state: event.state,
        city: event.city,
        startDate: event.startDate,
      },
    }

    const scrubstrApiQueryService = new ScrubstrApiQueryService()
    const incomeCalcDTO = await scrubstrApiQueryService.get<IncomeCalcDTO>(scrubstrApiQueryRequest)
    return incomeCalcDTO
  }
}
