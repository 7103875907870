import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { AppState, RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react'
import { Browser } from '@capacitor/browser'
import { isPlatform } from '@ionic/react'
import { AuthStateContext } from '../providers/AuthStateContext'

const Login = (): JSX.Element => {
  const isMobileDevice = isPlatform('android') || isPlatform('ios')
  const { loginWithRedirect } = useAuth0()
  const { redirect } = useParams<{ redirect?: string }>()

  useEffect(() => {
    // separate redirectUrl like this since mobile devices don't get appState after redirect
    AuthStateContext.setRedirectUrl(redirect ? decodeURIComponent(redirect) : '/')

    let loginParams: RedirectLoginOptions<AppState> = {
      authorizationParams: { scope: 'profile email read:current_user' },
      appState: { redirectUrl: redirect ? decodeURIComponent(redirect) : '/' },
    }
    if (isMobileDevice) {
      loginParams = {
        ...loginParams,
        async openUrl(url) {
          // Redirect using Capacitor's Browser plugin
          await Browser.open({
            url,
            windowName: '_self',
          })
        },
      }
    }

    loginWithRedirect(loginParams)
  }, [redirect])

  return <div>Redirecting to login...</div>
}

export default Login
