import React from 'react'
import { isPlatform } from '@ionic/react'
import { Link, useHistory } from 'react-router-dom'
import { TopNav } from './TopNav'

export const TopLogo = (): JSX.Element => {
  const history = useHistory()

  return (
    <div
      className={`z-10 relative sm:py-3 py-8 px-4 ${isPlatform('iphone') ? 'pt-16' : ''}`}
      style={{ boxShadow: '0px 12px 18px -6px rgb(0 0 0 / 5%)' }}>
      <div className='flex justify-around'>
        {isPlatform('iphone') && (
          <div className='flex items-center p-4 text-dark-blue cursor-pointer' onClick={() => history.goBack()}>
            <svg className='h-4 w-4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'>
              <path d='M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z' />
            </svg>
          </div>
        )}
        <div className='flex h-12 w-6/12 items-center self-center'>
          <Link to='/' className='block sm:h-12'>
            <img className='max-h-full' src='/assets/HQ1.png' alt='scrubstr' />
          </Link>
        </div>
        <TopNav />
      </div>
    </div>
  )
}
