// with a param of test, this will get the full "test=bacon"
function getParamFromUrl(param: string) {
  const url = window.location.href
  let paramPos = url.indexOf('#' + param + '=')
  if (paramPos === -1) {
    paramPos = url.indexOf('&' + param + '=')
  }
  if (paramPos === -1) {
    paramPos = url.indexOf('?' + param + '=')
  }
  let postParamSearch = url.indexOf('#', paramPos + 1)
  if (postParamSearch === -1) {
    postParamSearch = url.indexOf('&', paramPos + 1)
  }
  if (paramPos === -1) {
    return false
  } else if (postParamSearch !== -1) {
    return url.substring(paramPos, postParamSearch)
  } else {
    return url.substring(paramPos)
  }
}

// with a param of test and in the url is "test=bacon", this will return "bacon"
export function getParamValueFromUrl(param: string) {
  const currentParam = getParamFromUrl(param)
  if (currentParam) {
    const beforeEqual = currentParam.indexOf('=')
    const value = currentParam.substring(beforeEqual + 1)
    return decodeURIComponent(value)
  } else {
    return false
  }
}

// with a param of test, this will get the full "test=bacon"
// only supports getting hash params vs getParamFromUrl() which gets GET params too
function getHashParamFromUrl(param: string) {
  const url = window.location.href
  const paramPos = url.indexOf('#' + param + '=')
  let postParamSearch = url.indexOf('#', paramPos + 1)
  if (postParamSearch === -1) {
    postParamSearch = url.indexOf('&', paramPos + 1)
  }
  if (paramPos === -1) {
    return false
  } else if (postParamSearch !== -1) {
    return url.substring(paramPos, postParamSearch)
  } else {
    return url.substring(paramPos)
  }
}

// with a param of test and in the url is "test=bacon", this will return "bacon"
// only supports getting hash param values vs getParamValueFromUrl() which gets GET param values too
export function getHashParamValueFromUrl<T = string>(param: string): T | false {
  const currentParam = getHashParamFromUrl(param)
  if (currentParam) {
    const beforeEqual = currentParam.indexOf('=')
    const value = currentParam.substring(beforeEqual + 1)
    return decodeURIComponent(value) as T
  } else {
    return false
  }
}

// this will add or update the param in the url with the value provided
// only works with hash params because GET params cannot be modified without a page refresh
export function addHashParamToUrl(
  param: string,
  value: string | number | boolean,
  replace = false,
  encodeValue = true,
) {
  const url = window.location.href
  const currentParam = getHashParamFromUrl(param)
  let newUrl = currentParam ? url.replace(currentParam, '') : url
  let newParam = ''
  if (encodeValue) {
    newParam = '#' + param + '=' + encodeURIComponent(value)
  } else {
    newParam = '#' + param + '=' + value
  }
  newUrl += newParam
  if (replace) {
    window.location.replace(newUrl)
  } else {
    window.location.href = newUrl
  }
}

// this will remove the param from the url
// only works with hash params because GET params cannot be modified without a page refresh
export function removeHashParamFromUrl(param: string, replace = false) {
  const url = window.location.href
  const currentParam = getHashParamFromUrl(param)
  const newUrl = currentParam ? url.replace(currentParam, '') : url
  if (replace) {
    window.location.replace(newUrl)
  } else {
    window.location.href = newUrl
  }
}

export function checkIsUpTree(node: unknown | null, search: Element | null): boolean {
  if (typeof node === 'undefined' || node === null) {
    return false
  }

  const nodeToCheck = node as Element

  if (nodeToCheck.parentNode === search) {
    return true
  } else if (nodeToCheck.parentNode === null) {
    return false
  } else {
    return checkIsUpTree(nodeToCheck.parentNode, search)
  }
}

function getScrollParent(node: Element): Element | null {
  try {
    if (node == null) {
      return null
    }

    const overflowY = window.getComputedStyle(node).overflowY
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden'
    if (isScrollable && node.scrollHeight > node.clientHeight) {
      return node
    } else {
      return getScrollParent(node.parentNode as Element)
    }
  } catch (err) {
    return null
  }
}

export function scrollIntoView(element: Element, offset?: number) {
  const scrollParent = getScrollParent(element)
  if (scrollParent !== null) {
    const parentHeight = scrollParent.getBoundingClientRect().height
    let yOffset = 0
    if (typeof offset !== 'undefined') {
      yOffset = offset
    } else {
      yOffset = parentHeight * 0.2 * -1
      if (yOffset > -100) {
        yOffset = -100
      }
    }
    const y = element.getBoundingClientRect().top + scrollParent.scrollTop + yOffset
    scrollParent.scrollTo(0, y)
  }
}

export function removeTrailingSlash(string: string) {
  return string.replace(/\/$/, '')
}

export const getObjectKeyFromValue = (object: Record<string, unknown>, value: unknown): string | undefined => {
  return Object.keys(object)[Object.values(object).indexOf(value)]
}

// Helper used by IntRange
type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>

/*
 * Generic typing that allows typing as a range of numbers
 * The range will include the From number and exclude the To number
 *
 * Example: IntRange<1, 5> = 1 | 2 | 3 | 4
 */
export type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>
