import React from 'react'

type ErrorViewProps = {
  children: React.ReactElement | React.ReactNode | string
}
export const ErrorView = ({ children }: ErrorViewProps) => {
  return (
    <div className='px-4 pt-6 text-center'>
      <span className='text-2xl'>{children}</span>
    </div>
  )
}
