import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Agency } from '../models/Agency.model'
import { FormSubmissionSummaryDTO } from '../models/FormSubmissionSummaryDTO.model'

import { AgencyService } from '../services/AgencyService/AgencyService'

import { AgencyFiveFaceRating } from '../components/AgencyFiveFaceRating'
import { ErrorView } from '../components/Error'
import { Footer } from '../components/Footer'
import { Loading } from '../components/Loading'
import { TopLogo } from '../components/TopLogo'
import { BottomNav } from '../components/BottomNav'

//-----------------------------------------------------------------------------
// SUB COMPONENTS
//-----------------------------------------------------------------------------

type AgencySummaryItemProps = {
  summary: {
    name: string
    average: number
  }
  agency: Agency
}
const AgencySummaryItem = ({ summary, agency }: AgencySummaryItemProps) => {
  let faceColorBG = '/assets/faces/scrubstr-faces-3.png'
  if (summary.average > 0 && summary.average <= 1) {
    faceColorBG = '/assets/faces/scrubstr-faces-1-color.png'
  }
  if (summary.average > 1 && summary.average <= 2) {
    faceColorBG = '/assets/faces/scrubstr-faces-2-color.png'
  }
  if (summary.average > 2 && summary.average <= 3) {
    faceColorBG = '/assets/faces/scrubstr-faces-3-color.png'
  }
  if (summary.average > 3 && summary.average <= 4) {
    faceColorBG = '/assets/faces/scrubstr-faces-4-color.png'
  }
  if (summary.average > 4) {
    faceColorBG = '/assets/faces/scrubstr-faces-5-color.png'
  }

  return (
    <li className='mb-6 text-center lg:flex lg:justify-start lg:gap-3'>
      <img src={faceColorBG} alt={`face rating ${summary.average}`} className='w-20 m-auto' />
      <div className='lg:self-center lg:flex-auto lg:text-left'>
        <div className='mb-2 text-3xl text-gray-600'>{summary.name}</div>
        {!(summary.average > 0) && (
          <Link to={`/review-agency/${agency.id}`} className='text-orange text-lg'>
            Be the first to review!
          </Link>
        )}
        {summary.average > 0 && <div className='text-3xl text-gray-600'>{summary.average} / 5</div>}
      </div>
    </li>
  )
}

type ReviewItemProps = {
  review: FormSubmissionSummaryDTO
}
const AgencyReviewItem = ({ review }: ReviewItemProps) => {
  let faceColorBG = '/assets/faces/scrubstr-faces-3.png'
  if (review.averageRating > 0 && review.averageRating <= 1) {
    faceColorBG = '/assets/faces/scrubstr-faces-1-color.png'
  }
  if (review.averageRating > 1 && review.averageRating <= 2) {
    faceColorBG = '/assets/faces/scrubstr-faces-2-color.png'
  }
  if (review.averageRating > 2 && review.averageRating <= 3) {
    faceColorBG = '/assets/faces/scrubstr-faces-3-color.png'
  }
  if (review.averageRating > 3 && review.averageRating <= 4) {
    faceColorBG = '/assets/faces/scrubstr-faces-4-color.png'
  }
  if (review.averageRating > 4) {
    faceColorBG = '/assets/faces/scrubstr-faces-5-color.png'
  }

  return (
    <li className='mb-6 border p-4 lg:flex lg:gap-6'>
      <img src={faceColorBG} alt={`face rating ${review.averageRating}`} className='w-32 m-auto' />
      <div className='lg:flex-auto'>
        <div className='mb-2 font-semibold text-md text-orange'>{dayjs(review.createdAt).format('MMMM D, YYYY')}</div>
        <AgencyFiveFaceRating rating={review.averageRating} />
        <p className='text-md' dangerouslySetInnerHTML={{ __html: review.additionalFeedback }}></p>
      </div>
    </li>
  )
}

//-----------------------------------------------------------------------------
// MAIN COMPONENT
//-----------------------------------------------------------------------------

const AgencyDetail = (): JSX.Element => {
  const { permalink } = useParams<{ permalink?: string }>()
  const [agency, setAgency] = useState<Agency>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>()
  const history = useHistory()

  const agencyService = new AgencyService()

  useEffect(() => {
    let searchOneOptions: {
      id?: number
      permalink?: string
    } = {
      permalink: permalink || '',
    }
    // check if the permalink in the url is a number indicating they are actually passing the id
    if (permalink && !isNaN(permalink as unknown as number)) {
      searchOneOptions = {
        id: parseInt(permalink),
      }
    }
    setIsLoading(true)
    agencyService
      .searchOne(searchOneOptions)
      .then((resultAgency) => {
        setIsLoading(false)
        setAgency(resultAgency)
      })
      .catch((err) => {
        console.error(err)
        if (err.status === 404) {
          history.push('/404')
        } else {
          setError(err.message)
          setIsLoading(false)
        }
      })
  }, [permalink])

  return (
    <div id='page-root' className='h-screen overflow-auto relative'>
      <TopLogo />

      <div className='mt-6 px-4 max-w-xl lg:max-w-6xl mx-auto relative'>
        {error && <ErrorView>{error}</ErrorView>}
        {isLoading && !error && <Loading />}
        {!isLoading && !error && agency && (
          <>
            <Helmet>
              <title>{agency.name}</title>
              <meta property='og:title' content={agency.name} />
              <meta
                property='og:image'
                content={`https://scrubstr-prod.s3.amazonaws.com/agencies/agency_${agency.id}.png`}
              />
            </Helmet>

            <div className='mb-6'>
              <Link className='text-md bg-dark-blue rounded p-1 px-2 text-white' to='/agencies'>
                Search All
              </Link>
            </div>
            <div className='mb-6'>
              <img
                className='mx-auto max-h-40 mb-6'
                src={agency.image ? agency.image : undefined}
                alt={`Image for ${agency.name}`}
              />
              <div className='text-center text-2xl font-semibold'>{agency.name}</div>
            </div>
            <div className='mb-6 text-center text-xl'>
              {agency.website && (
                <div>
                  <a
                    href={`${!agency.website.startsWith('http') ? 'http://' : ''}${agency.website}`}
                    target='_blank'
                    rel='noreferrer'
                    className='text-orange'>
                    {agency.website}
                  </a>
                </div>
              )}
              {agency.phone && (
                <div>
                  <a
                    href={`tel:${agency.phone.replace(/\D/g, '')}`}
                    target='_blank'
                    rel='noreferrer'
                    className='text-orange'>
                    {agency.phone}
                  </a>
                </div>
              )}
              {agency.facebook && (
                <div>
                  <a
                    href={`${!agency.facebook.startsWith('http') ? 'http://' : ''}${agency.facebook}`}
                    target='_blank'
                    rel='noreferrer'
                    className='text-orange'>
                    Facebook
                  </a>
                </div>
              )}
              {agency.address}
            </div>
            <div className='mb-6 text-center'>
              <AgencyFiveFaceRating agencyID={agency.id} rating={agency.rating} />
            </div>
            <div className='mb-12 border'>
              <div className='border-b p-2 px-6 bg-gray-100'>
                <h3 className='text-orange font-semibold'>Summary</h3>
              </div>
              <div className='p-2 py-6'>
                <div className='lg:flex lg:flex-row-reverse lg:justify-between lg:mx-8'>
                  <div className='lg:mt-4'>
                    {agency.rating > 0 && (
                      <div className='mb-4 text-center font-bold text-4xl text-gray-600'>
                        {agency.rating} / 5
                        <div className='text-xs text-gray-500'>
                          {agency.reviewCount} review{agency.reviewCount && agency.reviewCount > 1 ? 's' : ''}
                        </div>
                      </div>
                    )}
                    <div className='mb-4 text-center'>
                      <AgencyFiveFaceRating agencyID={agency.id} rating={agency.rating} />
                    </div>
                    <div className='mb-4 text-center'>
                      <Link
                        to={`/review-agency/${agency.id}`}
                        className='bg-orange font-semibold rounded p-4 px-6 text-xl inline-block text-white'>
                        Write A Review
                      </Link>
                    </div>
                  </div>

                  <ul className='my-8'>
                    {agency.ratingsSummary?.map((x, i) => (
                      <AgencySummaryItem summary={x} agency={agency} key={i} />
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {Array.isArray(agency.reviews) && agency.reviews.length > 0 && (
              <div className='mb-8'>
                <h3 id='detail-page-reviews' className='text-3xl' tabIndex={-1}>
                  Reviews Received
                </h3>
                <ul className='my-8'>
                  {agency.reviews.map((x, i) => (
                    <AgencyReviewItem review={x} key={i} />
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
      </div>

      <div className='mt-12'>
        <Footer />
      </div>

      <BottomNav />
    </div>
  )
}

export default AgencyDetail
