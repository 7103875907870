import React, { ReactNode } from 'react'
import { AppState, RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react'
import { Browser } from '@capacitor/browser'
import { isPlatform } from '@ionic/react'
import { AuthStateContext } from '../providers/AuthStateContext'

interface LoginButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children?: ReactNode
}
export const LoginButton = (props: LoginButtonProps): JSX.Element => {
  const { children, ...otherProps } = props
  const isMobileDevice = isPlatform('android') || isPlatform('ios')
  const { loginWithRedirect } = useAuth0()

  return (
    <button
      {...otherProps}
      onClick={() => {
        // separate redirectUrl like this since mobile devices don't get appState after redirect
        AuthStateContext.setRedirectUrl(window.location.pathname)

        let loginParams: RedirectLoginOptions<AppState> = {
          authorizationParams: { scope: 'profile email read:current_user' },
          appState: { redirectUrl: window.location.pathname },
        }
        if (isMobileDevice) {
          loginParams = {
            ...loginParams,
            async openUrl(url) {
              // Redirect using Capacitor's Browser plugin
              await Browser.open({
                url,
                windowName: '_self',
              })
            },
          }
        }
        loginWithRedirect(loginParams)
      }}>
      {children || 'Login'}
    </button>
  )
}
