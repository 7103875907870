import React, { useEffect } from 'react'
import { AppState, RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react'
import { Browser } from '@capacitor/browser'
import { isPlatform } from '@ionic/react'
import { AuthStateContext } from '../providers/AuthStateContext'

const Register = (): JSX.Element => {
  const isMobileDevice = isPlatform('android') || isPlatform('ios')
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    // separate redirectUrl like this since mobile devices don't get appState after redirect
    AuthStateContext.setRedirectUrl(window.location.pathname)

    let loginParams: RedirectLoginOptions<AppState> = {
      authorizationParams: { screen_hint: 'signup', scope: 'profile email read:current_user' },
      appState: { redirectUrl: window.location.pathname },
    }
    if (isMobileDevice) {
      loginParams = {
        ...loginParams,
        async openUrl(url) {
          // Redirect using Capacitor's Browser plugin
          await Browser.open({
            url,
            windowName: '_self',
          })
        },
      }
    }

    loginWithRedirect(loginParams)
  }, [])

  return <div>Redirecting to register...</div>
}
export default Register
