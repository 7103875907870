import React, { useEffect, useRef, useState } from 'react'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import { useParams } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { scrollIntoView } from '../utils'

import { FormServiceAddSubmissionAnswerEvent } from '../events/FormServiceAddSubmissionAnswerEvent'
import { FormFieldDTO } from '../models/FormFieldDTO.model'
import { FormFieldTypes } from '../models/FormFieldTypes.model'
import { FormPageDTO } from '../models/FormPageDTO.model'
import { FormRelations } from '../models/FormRelations.model'
import { FormSubmissionError } from '../models/FormSubmissionError.model'
import { UserDTO } from '../models/UserDTO.model'

import { AgencyService } from '../services/AgencyService/AgencyService'
import { FormService } from '../services/FormService/FormService'
import { HospitalService } from '../services/HospitalService/HospitalService'
import UserService from '../services/UserService/UserService'

import { Footer } from '../components/Footer'
import { Loading } from '../components/Loading'
import { TopLogo } from '../components/TopLogo'
import { BottomNav } from '../components/BottomNav'

const reviewTypeToFormId = {
  agency: 2,
  hospital: 1,
}

type FieldValues = Record<string | number, unknown>

type UpdateFieldValueMap = (fieldID: string | number, value: unknown) => void

type ValidationErrors = Record<string, string | JSX.Element>

type FormFieldElement = {
  field: FormFieldDTO
  updateFieldValueMap: UpdateFieldValueMap
  validationErrors: ValidationErrors
}

const SectionListItem = ({ field }: FormFieldElement) => {
  return (
    <div>
      <h2 className='text-3xl text-center font-semibold text-dark-blue'>{field.label}</h2>
      {/* <hr className='mt-4' /> */}
    </div>
  )
}

const TextAreaListItem = ({ field, updateFieldValueMap, validationErrors }: FormFieldElement) => {
  return (
    <div id={`review-input-${field.id}`}>
      <label className='block text-lg text-center text-dark-blue'>{field.label}</label>
      <textarea
        className='p-2 mt-2 w-full border border-solid border-gray-300 rounded'
        onChange={(evt) => {
          updateFieldValueMap(field.id, evt.target.value)
        }}
        onFocus={(evt) => {
          scrollIntoView(evt.target)
        }}
      />
      {validationErrors[`field_${field.id}`] && (
        <div className='validation-error text-red-500' tabIndex={-1}>
          {validationErrors[`field_${field.id}`]}
        </div>
      )}
    </div>
  )
}

const TextListItem = ({ field, updateFieldValueMap, validationErrors }: FormFieldElement) => {
  const [tempTextValue, setTempTextValue] = useState<string>('')

  useEffect(() => {
    updateFieldValueMap(field.id, tempTextValue)
  }, [tempTextValue])

  return (
    <div id={`review-input-${field.id}`}>
      <label className='block text-lg text-center text-dark-blue'>{field.label}</label>
      <input
        className='mt-2 w-full border border-solid border-gray-300 p-2 rounded'
        onChange={(evt) => {
          setTempTextValue(evt.target.value)
        }}
        onFocus={(evt) => {
          scrollIntoView(evt.target)
        }}
        value={tempTextValue}
      />
      {validationErrors[`field_${field.id}`] && (
        <div className='validation-error text-red-500' tabIndex={-1}>
          {validationErrors[`field_${field.id}`]}
        </div>
      )}
    </div>
  )
}

const SelectListItem = ({
  field,
  updateFieldValueMap,
  userDetails,
  validationErrors,
}: FormFieldElement & { userDetails: UserDTO }) => {
  const { id } = useParams<{ id: string }>()

  let fieldDefault = field.choices && field.choices[0]?.value ? field.choices[0].value : ''
  if (field.defaultValue === '{user:nursing_specialization}') {
    if (field.choices?.length) {
      const choice = field.choices.find((choice) => choice.value === userDetails.nursing_specialization)
      if (choice) {
        fieldDefault = choice.value
      } else {
        fieldDefault = field.choices[0].value
      }
    } else {
      fieldDefault = userDetails.nursing_specialization
    }
  } else if (field.defaultValue === '{user:position_title}') {
    if (field.choices?.length) {
      const choice = field.choices.find((choice) => choice.value === userDetails.position_title)
      if (choice) {
        fieldDefault = choice.value
      } else {
        fieldDefault = field.choices[0].value
      }
    } else {
      fieldDefault = userDetails.position_title
    }
  } else if (id && (field.inputName === 'a' || field.inputName === 'h')) {
    fieldDefault = id
  }

  useEffect(() => {
    if (field.defaultValue === '{user:nursing_specialization}') {
      let newValue = ''
      if (field.choices?.length) {
        const choice = field.choices.find((choice) => choice.value === userDetails.nursing_specialization)
        if (choice) {
          newValue = choice.value
        } else {
          newValue = field.choices[0].value
        }
      } else {
        newValue = userDetails.nursing_specialization
      }
      updateFieldValueMap(field.id, newValue)
    } else if (field.defaultValue === '{user:position_title}') {
      let newValue = ''
      if (field.choices?.length) {
        const choice = field.choices.find((choice) => choice.value === userDetails.position_title)
        if (choice) {
          newValue = choice.value
        } else {
          newValue = field.choices[0].value
        }
      } else {
        newValue = userDetails.position_title
      }
      updateFieldValueMap(field.id, newValue)
    } else if (field.inputName === 'a' || field.inputName === 'h') {
      updateFieldValueMap(field.id, id)
    } else if (field.choices?.length) {
      updateFieldValueMap(field.id, field.choices[0].value)
    }
  }, [id])

  return (
    <div id={`review-input-${field.id}`}>
      <label className='block text-lg text-center text-dark-blue'>{field.label}</label>
      <select
        className='w-full mt-2 py-2 px-2 rounded border border-solid border-gray-300'
        onChange={(evt) => {
          updateFieldValueMap(field.id, evt.target.value)
        }}
        defaultValue={fieldDefault}>
        {field.choices?.map((choice) => (
          <option key={choice.value} value={choice.value}>
            {choice.label}
          </option>
        ))}
      </select>
      {validationErrors[`field_${field.id}`] && (
        <div className='validation-error text-red-500' tabIndex={-1}>
          {validationErrors[`field_${field.id}`]}
        </div>
      )}
    </div>
  )
}

const NumberListItem = ({ field, updateFieldValueMap, validationErrors }: FormFieldElement) => {
  return (
    <div id={`review-input-${field.id}`}>
      <label className='block text-lg text-center text-dark-blue'>{field.label}</label>
      <input
        className='mt-2 w-full border border-solid border-gray-300 p-2 rounded'
        type='number'
        onChange={(evt) => {
          updateFieldValueMap(field.id, evt.target.value)
        }}
      />
      {validationErrors[`field_${field.id}`] && (
        <div className='validation-error text-red-500' tabIndex={-1}>
          {validationErrors[`field_${field.id}`]}
        </div>
      )}
    </div>
  )
}

type RadioFaceProps = {
  rating: number
  selected: number | null
  setSelected: React.Dispatch<React.SetStateAction<number | null>>
}
const RadioFace = ({ rating, selected, setSelected }: RadioFaceProps) => {
  const isSelected = rating === selected
  const faceURL = isSelected
    ? `/assets/faces/scrubstr-faces-${rating}-color.png`
    : `/assets/faces/scrubstr-faces-${rating}.png`
  return (
    <div
      style={{ backgroundImage: `url(${faceURL})` }}
      className='w-16 h-16 bg-contain cursor-pointer'
      onClick={() => {
        setSelected(rating)
        Haptics.impact({ style: ImpactStyle.Light })
      }}></div>
  )
}

const RadioListItem = ({ field, updateFieldValueMap, validationErrors }: FormFieldElement) => {
  const [selected, setSelected] = useState<number | null>(null)

  useEffect(() => {
    if (!selected) return

    updateFieldValueMap(field.id, selected)
  }, [selected])

  const choiceDescriptorMap: Record<number, string> = {}
  if (field.choices?.length) {
    choiceDescriptorMap[0] = 'Awful'
    choiceDescriptorMap[field.choices.length - 1] = 'Amazing!'
  }

  return (
    <div id={`review-input-${field.id}`}>
      <label className='block text-lg text-center mb-6 text-dark-blue'>{field.label}</label>
      <div className='mt-6 flex justify-between'>
        {field.choices?.map((choice, choiceIndex) => (
          <label className='w-full flex flex-col text-xs text-center items-center' key={choice.value}>
            {/* NOTE: making assumption that radios will always be faces */}
            {/* NOTE: making assumption that radios will only have five options ever */}
            <RadioFace rating={choiceIndex + 1} selected={selected} setSelected={setSelected} />
            {/* NOTE: Previously using x.text which is what is actually defined in the form but requested to only show Awful and Amazing at the beginning and end */}
            <span className='mt-2'>{choiceDescriptorMap[choiceIndex] || ''}</span>
          </label>
        ))}
      </div>
      {validationErrors[`field_${field.id}`] && (
        <div className='validation-error text-red-500' tabIndex={-1}>
          {validationErrors[`field_${field.id}`]}
        </div>
      )}
    </div>
  )
}

type ReviewType = 'hospital' | 'agency'
// type SetFieldValues = React.Dispatch<React.SetStateAction<any>>
const NodeListItem = (
  field: FormFieldDTO,
  updateFieldValueMap: UpdateFieldValueMap,
  userDetails: UserDTO,
  validationErrors: ValidationErrors,
): JSX.Element => {
  switch (field.type) {
    case FormFieldTypes.section:
      return (
        <SectionListItem
          updateFieldValueMap={updateFieldValueMap}
          key={field.id}
          field={field}
          validationErrors={validationErrors}
        />
      )
    case FormFieldTypes.number:
      return (
        <NumberListItem
          updateFieldValueMap={updateFieldValueMap}
          key={field.id}
          field={field}
          validationErrors={validationErrors}
        />
      )
    case FormFieldTypes.select:
      return (
        <SelectListItem
          updateFieldValueMap={updateFieldValueMap}
          key={field.id}
          field={field}
          userDetails={userDetails}
          validationErrors={validationErrors}
        />
      )
    case FormFieldTypes.text:
      return (
        <TextListItem
          updateFieldValueMap={updateFieldValueMap}
          key={field.id}
          field={field}
          validationErrors={validationErrors}
        />
      )
    case FormFieldTypes.radio:
      return (
        <RadioListItem
          updateFieldValueMap={updateFieldValueMap}
          key={field.id}
          field={field}
          validationErrors={validationErrors}
        />
      )
    case FormFieldTypes.textarea:
      return (
        <TextAreaListItem
          updateFieldValueMap={updateFieldValueMap}
          key={field.id}
          field={field}
          validationErrors={validationErrors}
        />
      )
    default:
      return <div className='text-red-500'>Error: did you forget to handle this type?</div>
  }
}

type submitReviewArgs = {
  formId: number
  fieldValues: FieldValues
  userDetails: UserDTO
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  setSubmissionGeneralErrorMessage: React.Dispatch<React.SetStateAction<string>>
  setValidationErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
  submitCallback: () => void
}
const submitReview = async ({
  formId,
  fieldValues,
  userDetails,
  setIsSubmitting,
  setSubmissionGeneralErrorMessage,
  setValidationErrors,
  submitCallback,
}: submitReviewArgs) => {
  setIsSubmitting(true)

  setValidationErrors({}) // reset errors

  // loop over the fieldValues and create an array of answers { formFieldId, value }
  const answers: FormServiceAddSubmissionAnswerEvent[] = []
  Object.entries(fieldValues).forEach(([fieldId, value]) => {
    answers.push({
      formFieldId: Number(fieldId),
      value: String(value),
    })
  })

  const formService = new FormService()
  try {
    await formService.addSubmission({
      formId,
      email: userDetails.email,
      answers,
    })
    submitCallback()
  } catch (error) {
    console.error(error)
    const errorWithDetails = (error as FormSubmissionError)?.data // assertion to support the error details property we add in the api
    if (errorWithDetails?.details?.length) {
      // convert the details array { fieldId, error } to an array with fieldId as the key and error as the value
      const validationErrors: ValidationErrors = errorWithDetails.details.reduce(
        (acc: ValidationErrors, errorDetail) => {
          acc[`field_${errorDetail.fieldId}`] = errorDetail.error
          return acc
        },
        {},
      )
      console.error(validationErrors)
      setValidationErrors(validationErrors)
      setSubmissionGeneralErrorMessage(errorWithDetails?.message || 'Sorry, there was a problem with your request.')
      setIsSubmitting(false)
    } else {
      setSubmissionGeneralErrorMessage('Sorry, there was a problem with your request.')
      setIsSubmitting(false)
    }
  }
}

type ReviewPageNavigationProps = {
  pages: FormPageDTO[]
  reviewPageIndex: number
  setReviewPageIndex: React.Dispatch<React.SetStateAction<number>>
}
const ReviewPageNavigation = ({ pages, reviewPageIndex, setReviewPageIndex }: ReviewPageNavigationProps) => {
  return (
    <div className='flex justify-center mb-6'>
      {pages.map((page, pageIndex) => {
        return (
          <div key={page.id} className={`p-2 cursor-pointer`} onClick={() => setReviewPageIndex(pageIndex)}>
            <div
              className={`h-4 w-4 rounded-full ${pageIndex === reviewPageIndex ? 'bg-orange' : 'bg-dark-blue'}`}></div>
          </div>
        )
      })}
    </div>
  )
}

type ErrorViewProps = {
  generalErrorMessage: string
}
const ErrorView = ({ generalErrorMessage }: ErrorViewProps) => {
  return (
    <div className='px-4 pt-6 text-center'>
      <span className='text-2xl' dangerouslySetInnerHTML={{ __html: generalErrorMessage }}></span>
    </div>
  )
}

type ReviewProps = {
  reviewType: ReviewType
}
const Review = ({ reviewType }: ReviewProps): JSX.Element => {
  const { user, isAuthenticated } = useAuth0()
  const [userDetails, setUserDetails] = useState<UserDTO>()
  const [pages, setPages] = useState<FormPageDTO[]>([])
  const [answeredPages, setAnsweredPages] = useState<number[]>([])
  const [formValid, setFormValid] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [reviewPageIndex, setReviewPageIndex] = useState<number>(0)
  const [generalErrorMessage, setGeneralErrorMessage] = useState('')
  const [submissionGeneralErrorMessage, setSubmissionGeneralErrorMessage] = useState('')
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})
  const [fieldValues, setFieldValues] = useState<FieldValues>({})
  const [validPages, setValidPages] = useState<Record<string, boolean>>({})
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams<{ id: string }>()
  const reviewHeaderRef = useRef<HTMLDivElement>(null)

  const formService = new FormService()

  const formId = reviewTypeToFormId[reviewType]

  useEffect(() => {
    // format the user data
    if (isAuthenticated && user) {
      setUserDetails(UserService.formatUser(user))
    }
  }, [isAuthenticated, user])

  useEffect(() => {
    if (!isAuthenticated) {
      history.replace(`/login/redirect/${encodeURIComponent(location.pathname)}`)
      return
    }
    setIsLoading(true)

    if (userDetails) {
      formService
        .get({ id: formId, relations: [FormRelations.pages, FormRelations.pagesFields] })
        .then((result) => {
          // before we filter out the hidden fields, set default values for all the fields
          result.pages?.forEach((page) => {
            page.fields?.forEach((field) => {
              if (field.defaultValue) {
                let fieldDefault = ''
                if (field.defaultValue === '{user:position_title}') {
                  if (field.choices?.length) {
                    const choice = field.choices.find((choice) => choice.value === userDetails.position_title)
                    if (choice) {
                      fieldDefault = choice.value
                    } else {
                      fieldDefault = field.choices[0].value
                    }
                  } else {
                    fieldDefault = userDetails.position_title
                  }
                } else if (field.defaultValue === '{user:nursing_specialization}') {
                  if (field.choices?.length) {
                    const choice = field.choices.find((choice) => choice.value === userDetails.nursing_specialization)
                    if (choice) {
                      fieldDefault = choice.value
                    } else {
                      fieldDefault = field.choices[0].value
                    }
                  } else {
                    fieldDefault = userDetails.nursing_specialization
                  }
                } else if (id && (field.inputName === 'a' || field.inputName === 'h')) {
                  fieldDefault = id
                }
                setFieldValues((prev) => {
                  return {
                    ...prev,
                    [String(field.id)]: String(fieldDefault),
                  }
                })
              }
            })
          })

          // loop over the pages and return only fields that are not hidden
          const pagesWithVisibleFields = result.pages?.map((page) => {
            return {
              ...page,
              fields: page.fields?.filter((field) => field.visible === true),
            }
          })

          // loop over the pages and then loop over the fields
          // if any are inputName 'h' or 'a', refetch the field details directly in order to obtain the hospital or agency choices
          // then update the field with the choices
          const promises: Promise<void>[] = []
          pagesWithVisibleFields?.forEach((page) => {
            page.fields?.forEach((field) => {
              if (field.inputName === 'h' || field.inputName === 'a') {
                promises.push(
                  new Promise((resolve, reject) => {
                    if (id) {
                      // an id was specified in the url so we only need to fetch the details of the hospital or agency based on the id
                      if (reviewType === 'hospital') {
                        const hospitalService = new HospitalService()
                        hospitalService
                          .searchOne({ id: Number(id) })
                          .then((hospitalResult) => {
                            field.choices = [{ value: String(hospitalResult.id), label: hospitalResult.name }]
                            resolve()
                          })
                          .catch((error) => {
                            console.error(error)
                            reject()
                          })
                      } else if (reviewType === 'agency') {
                        const agencyService = new AgencyService()
                        agencyService
                          .searchOne({ id: Number(id) })
                          .then((agencyResult) => {
                            field.choices = [{ value: String(agencyResult.id), label: agencyResult.name }]
                            resolve()
                          })
                          .catch((error) => {
                            console.error(error)
                            reject()
                          })
                      }
                    } else {
                      // no id was specified in the url so we need to fetch all the hospitals or agencies
                      formService
                        .getField({ id: field.id, relations: [] })
                        .then((fieldResult) => {
                          field.choices = fieldResult.choices
                          resolve()
                        })
                        .catch((error) => {
                          console.error(error)
                          reject()
                        })
                    }
                  }),
                )
              }
            })
          })
          Promise.all(promises)
            .then(() => {
              setPages(pagesWithVisibleFields || [])
              setIsLoading(false)
            })
            .catch((error) => {
              console.error(error)
              setGeneralErrorMessage('Sorry, there was a problem with your request.')
              setIsLoading(false)
            })
        })
        .catch((error) => {
          console.error(error)
          setGeneralErrorMessage('Sorry, there was a problem with your request.')
          setIsLoading(false)
        })
    }
  }, [isAuthenticated, userDetails])

  useEffect(() => {
    validateFields()
  }, [fieldValues, isLoading])

  useEffect(() => {
    if (pages.length) {
      pages.forEach((page, pageIndex) => {
        let hasError = false
        page.fields?.map((field) => {
          if (validationErrors[`field_${field.id}`]) {
            hasError = true
          }
        })
        if (hasError) {
          setReviewPageIndex(pageIndex)
          // scroll to the first error on this page
          const validationElements = document.getElementsByClassName('validation-error')
          if (validationElements.length > 0) {
            const firstValidationElement = validationElements[0]
            ;(firstValidationElement as HTMLElement)?.focus()
          }
          return // found first error so exit loop
        }
      })
    }
  }, [validationErrors])

  useEffect(() => {
    if (pages.length) scrollToReviewHeader()
  }, [reviewPageIndex])

  const submitCallback = () => {
    history.push(`/${{ agency: 'agencies', hospital: 'hospitals' }[reviewType]}/${id}`)
  }

  const scrollToReviewHeader = () => {
    if (reviewHeaderRef && reviewHeaderRef.current) scrollIntoView(reviewHeaderRef.current, 0)
  }

  const validateFields = () => {
    if (pages.length) {
      pages.map((page, pageIndex) => {
        let answeredAll = true
        validPages[`page_${page.id}`] = true
        page.fields?.map((field) => {
          if (![FormFieldTypes.section].includes(field.type)) {
            if (typeof fieldValues[field.id] === 'undefined') {
              // hidden fields are not validated
              if (field.visible && field.isRequired) {
                validPages[`page_${page.id}`] = false
              }
              answeredAll = false
            }
          }
        })
        if (answeredAll && !answeredPages.includes(pageIndex)) {
          answeredPages.push(pageIndex)

          if (pageIndex === reviewPageIndex && reviewPageIndex !== 0 && reviewPageIndex !== pages.length - 1) {
            setTimeout(() => {
              setReviewPageIndex(reviewPageIndex + 1)
            }, 500)
          }
        }
      })

      setAnsweredPages(answeredPages)
      setValidPages(validPages)

      setFormValid(true)
    }
  }

  const updateFieldValueMap: UpdateFieldValueMap = (fieldID, value) => {
    setFieldValues((prev) => {
      return {
        ...prev,
        [String(fieldID)]: String(value),
      }
    })
  }

  return (
    <div id='page-root' className='h-screen overflow-auto relative'>
      <TopLogo />

      {isAuthenticated && userDetails && (
        <div className='px-4 max-w-xl lg:max-w-6xl mx-auto relative'>
          <h1 className='mt-8 text-2xl text-center font-light capitalize' ref={reviewHeaderRef}>
            {reviewType} Review
          </h1>

          {generalErrorMessage.length > 0 && <ErrorView generalErrorMessage={generalErrorMessage} />}
          {(isLoading || isSubmitting) && !(generalErrorMessage.length > 0) && <Loading />}

          {!isLoading && !(generalErrorMessage.length > 0) && (
            <div className={`my-12 ${isSubmitting ? 'hidden' : ''}`}>
              <ReviewPageNavigation
                pages={pages}
                reviewPageIndex={reviewPageIndex}
                setReviewPageIndex={setReviewPageIndex}
              />
              {pages?.[0] && (
                <div className={`review-getting-started-page ${reviewPageIndex === 0 ? '' : 'hidden'}`}>
                  <ul className='space-y-12'>
                    {pages[0].fields?.map((field) =>
                      NodeListItem(field, updateFieldValueMap, userDetails, validationErrors),
                    )}
                  </ul>
                  <button
                    className='mt-8 w-full font-semibold bg-dark-blue rounded text-white py-4 lg:w-36 lg:mx-auto lg:block'
                    onClick={() => setReviewPageIndex(reviewPageIndex + 1)}>
                    Start
                  </button>
                </div>
              )}
              {pages.map((page, pageIndex) => {
                if (pageIndex !== 0 && pageIndex !== pages.length - 1 && page.fields?.length) {
                  // Not the first getting started page or the last wrapup page so proceed
                  return (
                    <div
                      key={page.id}
                      className={`review-ratings-page ${reviewPageIndex === pageIndex ? '' : 'hidden'}`}>
                      <ul className='space-y-12'>
                        {page.fields.map((field) =>
                          NodeListItem(field, updateFieldValueMap, userDetails, validationErrors),
                        )}
                      </ul>
                      <div className={`flex justify-center space-x-2 mt-12`}>
                        <button
                          className='mt-8 w-full lg:w-36 font-semibold bg-dark-blue rounded text-white py-4'
                          onClick={() => setReviewPageIndex(reviewPageIndex - 1)}>
                          Previous
                        </button>
                        <button
                          className='mt-8 w-full lg:w-36 font-semibold bg-dark-blue rounded text-white py-4'
                          onClick={() => setReviewPageIndex(reviewPageIndex + 1)}>
                          Next
                        </button>
                      </div>
                    </div>
                  )
                }
              })}
              {pages[pages.length - 1] && (
                <div className={`review-wrapup-page ${reviewPageIndex === pages.length - 1 ? '' : 'hidden'}`}>
                  <ul className='space-y-12'>
                    {pages[pages.length - 1].fields?.map((field) =>
                      NodeListItem(field, updateFieldValueMap, userDetails, validationErrors),
                    )}
                  </ul>
                  {submissionGeneralErrorMessage.length > 0 && (
                    <div
                      className='mt-4 text-red-500 text-center'
                      dangerouslySetInnerHTML={{ __html: submissionGeneralErrorMessage }}></div>
                  )}
                  {!formValid && (
                    <div className='mt-4 text-red-500 text-center'>Please complete your review before submitting.</div>
                  )}
                  {formValid && (
                    <button
                      className={`mt-8 w-full font-semibold bg-dark-blue text-white rounded py-4`}
                      onClick={() =>
                        submitReview({
                          formId,
                          fieldValues,
                          userDetails,
                          setIsSubmitting,
                          setSubmissionGeneralErrorMessage,
                          setValidationErrors,
                          submitCallback,
                        })
                      }>
                      SUBMIT
                    </button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <div className='mt-12'>
        <Footer />
      </div>

      <BottomNav />
    </div>
  )
}

export default Review
