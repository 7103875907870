import React from 'react'
import { useHistory } from 'react-router-dom'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { isPlatform } from '@ionic/react'

type Auth0ProviderWithHistoryProps = { children: React.ReactNode }

const Auth0ProviderWithHistory = ({ children }: Auth0ProviderWithHistoryProps): JSX.Element => {
  const isMobileDevice = isPlatform('android') || isPlatform('ios')
  // have to hardcode the auth0 prod creds in order to bypass paying a ton for Ionic since env support is a premium feature
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || 'scrubstr.us.auth0.com'
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || 'ordPFCeYFZnCoFDjLpIoX99xctbQ3j5Z'
  const nativeClientId = process.env.REACT_APP_AUTH0_NATIVE_CLIENT_ID || 'xRfwLUL5nGPmKCpicfeS3xaANeKqVH4w'

  const history = useHistory()

  const onRedirectCallback = (appState?: AppState) => {
    /**
     * Only works for non-mobile devices
     *
     * Auth0 always sends you to the value of the redirectUri which can't be dynamic
     * since it is restricted to only values in the allowed callback urls list in the
     * Auth0 dashboard so here, we immediately redirect to the correct page after the
     * Auth0 redirect.
     *
     * We've created a blank route for this purpose at /redirect to avoid wasting
     * resources on a component that we don't need to render.
     */
    history.replace(appState?.redirectUrl || `${window.location.origin}/profile`)
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={isMobileDevice ? nativeClientId : clientId}
      useRefreshTokens={isMobileDevice ? true : undefined}
      useRefreshTokensFallback={isMobileDevice ? false : undefined}
      authorizationParams={{
        redirect_uri: isMobileDevice
          ? `com.scrubstr.app://${domain}/capacitor/com.scrubstr.app/callback`
          : `${window.location.origin}/redirect`,
        audience: `https://${domain}/api/v2/`,
        scope: 'profile email read:current_user',
      }}
      onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
