import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { App as CapApp } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { isPlatform } from '@ionic/react'
import { useHistory } from 'react-router-dom'

import { Loading } from '../components/Loading'

type AuthStateContextType = {
  getToken: () => string
  setRedirectUrl: (url: string) => void
  getRedirectUrl: () => string
}
export const AuthStateContext: AuthStateContextType = {
  getToken: () => {
    // if used outside of AuthStateProvider, this function will not have been replaced so throw error
    throw new Error('AuthStateContext must be used within a AuthStateProvider')
  },
  setRedirectUrl: () => {
    // if used outside of AuthStateProvider, this function will not have been replaced so throw error
    throw new Error('AuthStateContext must be used within a AuthStateProvider')
  },
  getRedirectUrl: () => {
    // if used outside of AuthStateProvider, this function will not have been replaced so throw error
    throw new Error('AuthStateContext must be used within a AuthStateProvider')
  },
}

type AuthStateProviderProps = { children: React.ReactNode }

export const AuthStateProvider = ({ children }: AuthStateProviderProps): JSX.Element => {
  const isMobileDevice = isPlatform('android') || isPlatform('ios')
  // have to hardcode the auth0 prod domain in order to bypass paying a ton for Ionic since env support is a premium feature
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || 'scrubstr.us.auth0.com'

  const [authToken, setAuthToken] = React.useState<string>('')
  const [tokenLoading, setTokenLoading] = React.useState(false)
  const [redirectUrl, setRedirectUrl] = React.useState<string>('')
  const history = useHistory()

  const { user, isAuthenticated, getAccessTokenSilently, handleRedirectCallback } = useAuth0()

  useEffect(() => {
    if (isMobileDevice) {
      // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
      CapApp.addListener('appUrlOpen', async ({ url }) => {
        if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
          await handleRedirectCallback(url)
          history.push(AuthStateContext.getRedirectUrl() || '/profile')
          AuthStateContext.setRedirectUrl('')
        }
        // No-op on Android
        await Browser.close()
      })
    }
  }, [handleRedirectCallback])

  useEffect(() => {
    // format the user data
    if (isAuthenticated && user) {
      setTokenLoading(true)
      getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${domain}/api/v2/`,
          scope: 'read:current_user',
        },
      }).then((accessToken) => {
        // console.log(accessToken)
        setAuthToken(accessToken)
        setTokenLoading(false)
      })
    }
  }, [isAuthenticated, user])

  AuthStateContext.getToken = () => {
    return authToken
  }
  AuthStateContext.setRedirectUrl = (url: string) => {
    setRedirectUrl(url)
  }
  AuthStateContext.getRedirectUrl = () => {
    return redirectUrl
  }

  return (
    <>
      {tokenLoading ? (
        <div className='bg-light-blue flex justify-center items-center min-h-screen relative'>
          <Loading />
        </div>
      ) : (
        children
      )}
    </>
  )
}
