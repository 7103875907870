import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { Loading } from '../components/Loading'

type AppLoadingContextType = {
  setAppLoading: (newIsLoading?: boolean) => void
}
export const AppLoadingContext: AppLoadingContextType = {
  setAppLoading: () => {
    // if used outside of AppLoadingProvider, this function will not have been replaced so throw error
    throw new Error('AppLoadingContext must be used within a AppLoadingProvider')
  },
}

type AppLoadingProviderProps = { children: React.ReactNode }

export const AppLoadingProvider = ({ children }: AppLoadingProviderProps): JSX.Element => {
  const [appLoading, setAppLoading] = React.useState(true)

  const { isLoading } = useAuth0()

  useEffect(() => {
    setAppLoading(isLoading)
  }, [isLoading])

  AppLoadingContext.setAppLoading = (newIsLoading = true) => {
    setAppLoading(newIsLoading)
  }
  return (
    <>
      {appLoading ? (
        <div className='bg-light-blue flex justify-center items-center min-h-screen relative'>
          <Loading />
        </div>
      ) : (
        children
      )}
    </>
  )
}
