import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { addHashParamToUrl, checkIsUpTree, getHashParamValueFromUrl, scrollIntoView } from '../utils'

import { Hospital } from '../models/Hospital.model'
import { USStates } from '../models/USStates.model'

import { HospitalService } from '../services/HospitalService/HospitalService'

import { ErrorView } from '../components/Error'
import { Footer } from '../components/Footer'
import { HospitalFiveFaceRating } from '../components/HospitalFiveFaceRating'
import { Loading } from '../components/Loading'
import { Paging } from '../components/Paging'
import { TopLogo } from '../components/TopLogo'
import { BottomNav } from '../components/BottomNav'

//-----------------------------------------------------------------------------
// SUB COMPONENTS
//-----------------------------------------------------------------------------

const HeroSection = () => {
  return (
    <>
      {/* Mobile View */}
      <div className='md:hidden'>
        <div className='m-auto' style={{ maxWidth: '90vw' }}>
          <h2 className='font-semibold mt-4 text-center text-4xl leading-normal'>Discover Hospitals</h2>
        </div>
        <img src='/assets/hospital-header.png' alt='Hospital Header' className='max-h-80 m-auto' />
      </div>
      {/* Desktop View */}
      <div
        style={{ backgroundImage: `url(/assets/scrubstr-usa-wide.png)` }}
        className='hidden md:block py-40 bg-cover bg-center bg-dark-blue text-white'>
        <div className='m-auto' style={{ maxWidth: '90vw' }}>
          <h2 className='font-semibold text-shadow mt-4 text-center text-5xl leading-normal'>Discover Hospitals</h2>
          <h3 className='font-semibold text-shadow mt-4 text-center text-2xl leading-normal'>
            Search our database of 7000+ US Hospitals
          </h3>
        </div>
      </div>
    </>
  )
}

type FilterProps = {
  currentCityStateSearchTerm: string
  currentSearchTerm: string
  setNewCityStateSearchTerm: (newTerm: string) => void
  setNewSearchTerm: (newTerm: string) => void
  setAutoCompleteCityStateTerm: React.Dispatch<React.SetStateAction<string>>
  setAutoCompleteTerm: React.Dispatch<React.SetStateAction<string>>
  autoCompleteCityStateResults: { city: string; state: USStates; stateName: string }[]
  autoCompleteResults: Hospital[]
}
const Filter = ({
  currentCityStateSearchTerm,
  currentSearchTerm,
  setNewCityStateSearchTerm,
  setNewSearchTerm,
  setAutoCompleteCityStateTerm,
  setAutoCompleteTerm,
  autoCompleteCityStateResults,
  autoCompleteResults,
}: FilterProps) => {
  const [cityStateTermFocused, setCityStateTermFocused] = useState(false)
  const [termFocused, setTermFocused] = useState(false)
  const [tempSearchTerm, setTempSearchTerm] = useState(currentSearchTerm)
  const [tempCityStateSearchTerm, setTempCityStateSearchTerm] = useState(currentCityStateSearchTerm)
  const autoCompleteContainerRef = useRef<HTMLDivElement>(null)
  const autoCompleteCityStateContainerRef = useRef<HTMLDivElement>(null)

  return (
    <div className='lg:flex justify-center'>
      <div className='flex-auto'>
        <input
          value={tempSearchTerm}
          onChange={(evt) => setTempSearchTerm(evt.target.value)}
          onKeyUp={(evt) => {
            if (evt.key === 'Enter') {
              setNewSearchTerm(tempSearchTerm)
              setTermFocused(false)
            } else {
              setAutoCompleteTerm(tempSearchTerm)
              setTermFocused(true)
            }
          }}
          onFocus={(evt) => {
            scrollIntoView(evt.target)
          }}
          onBlur={(evt) => {
            if (!checkIsUpTree(evt.relatedTarget, autoCompleteContainerRef.current)) {
              setTermFocused(false)
            }
          }}
          className='px-4 py-4 rounded-sm md:rounded-r-none border border-solid border-gray-300 md:border-r-0 w-full'
          placeholder='Hospital Name'
        />
        <div className='relative'>
          {autoCompleteResults.length > 0 && termFocused && (
            <div className='absolute bg-white left-0 right-0 shadow-2xl z-50' ref={autoCompleteContainerRef}>
              {autoCompleteResults.map((hospital) => (
                <Link
                  key={hospital.id}
                  className='border-b p-2 block w-full'
                  to={`/hospitals/${hospital.permalink}`}
                  dangerouslySetInnerHTML={{ __html: hospital.name }}
                  tabIndex={0}></Link>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className='flex-auto'>
        <input
          value={tempCityStateSearchTerm}
          onChange={(evt) => setTempCityStateSearchTerm(evt.target.value)}
          onKeyUp={(evt) => {
            if (evt.key === 'Enter') {
              const isMatchingResult = autoCompleteCityStateResults.find(
                (cityState) => tempCityStateSearchTerm?.toLowerCase() === `${cityState.city}, ${cityState.stateName}`,
              )
              const isForceMatch =
                tempCityStateSearchTerm !== '' && autoCompleteCityStateResults.length && !isMatchingResult
              const calculatedTerm = isForceMatch
                ? `${autoCompleteCityStateResults[0].city}, ${autoCompleteCityStateResults[0].stateName}`
                : !autoCompleteCityStateResults.length
                ? ''
                : tempCityStateSearchTerm

              setNewCityStateSearchTerm(calculatedTerm)
              if (tempCityStateSearchTerm !== calculatedTerm) {
                setTempCityStateSearchTerm(calculatedTerm)
              }
              setNewSearchTerm(tempSearchTerm)
              setCityStateTermFocused(false)
            } else {
              setAutoCompleteCityStateTerm(tempCityStateSearchTerm)
              setCityStateTermFocused(true)
            }
          }}
          onFocus={(evt) => {
            scrollIntoView(evt.target)
          }}
          onBlur={(evt) => {
            if (!checkIsUpTree(evt.relatedTarget, autoCompleteCityStateContainerRef.current)) {
              if (currentCityStateSearchTerm !== tempCityStateSearchTerm) {
                setTempCityStateSearchTerm(currentCityStateSearchTerm)
              }
              setCityStateTermFocused(false)
            }
          }}
          className='px-4 py-4 rounded-sm md:rounded-r-none border border-solid border-gray-300 md:border-r-0 w-full'
          placeholder='City, State'
        />
        <div className='relative'>
          {autoCompleteCityStateResults.length > 0 && cityStateTermFocused && (
            <div className='absolute bg-white left-0 right-0 shadow-2xl z-50' ref={autoCompleteCityStateContainerRef}>
              {autoCompleteCityStateResults.map((cityState) => (
                <button
                  key={`${cityState.city},${cityState.state}`}
                  className='border-b p-2 block w-full'
                  onClick={() => {
                    setTempCityStateSearchTerm(`${cityState.city}, ${cityState.stateName}`)
                    setNewCityStateSearchTerm(`${cityState.city}, ${cityState.stateName}`)
                    setNewSearchTerm(tempSearchTerm)
                    setCityStateTermFocused(false)
                  }}
                  tabIndex={0}>
                  {cityState.city}, {cityState.stateName}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className='mt-4 lg:mt-0'>
        <button
          className='px-4 py-4 rounded-sm text-white font-semibold uppercase w-full'
          onClick={() => {
            setNewCityStateSearchTerm(tempCityStateSearchTerm)
            setNewSearchTerm(tempSearchTerm)
          }}
          style={{ background: '#303c6c' }}>
          Search
        </button>
      </div>
    </div>
  )
}

type SortProps = {
  sortValue: string
  setSortValue: (sortValue: string) => void
  totalResults: number
  numberShowing: number
  page: number
}
const Sort = ({ sortValue, setSortValue, totalResults, numberShowing, page }: SortProps) => (
  <div className='mt-12 lg:flex justify-between'>
    <div>
      <label className='lg:flex lg:gap-3'>
        <div className='lg:self-center'>Sort:</div>
        <div>
          <select
            value={sortValue}
            onChange={(evt) => setSortValue(evt.target.value)}
            className='px-4 py-4 lg:p-2 rounded-sm border border-solid border-gray-300 w-full lg:bg-white'>
            {Object.entries({
              highRating: 'Top Rated',
              lowRating: 'Lowest Rated',
              az: 'A - Z',
              za: 'Z - A',
            }).map((x) => (
              <option key={x[0]} value={x[0]}>
                {x[1]}
              </option>
            ))}
          </select>
        </div>
      </label>
    </div>

    {totalResults > 0 && (
      <div className='mt-4 lg:mt-0 self-center text-gray-600'>
        {(page - 1) * 12 + (numberShowing > 0 ? 1 : 0)}-{(page - 1) * 12 + numberShowing} of {totalResults} Results
      </div>
    )}
  </div>
)

type RatingProps = {
  score: number
}
const Rating = ({ score }: RatingProps) => <div className='text-3xl text-gray-600'>{score} / 5</div>

// TODO: add link for hospital reviews subsection of hospital detail page on read reviews (current site uses hashtag scroll)
const HospitalListItem = (hospital: Hospital) => (
  <li
    key={hospital.id}
    className='text-center border border-solid border-gray-300 rounded-sm px-2 py-8 lg:py-0 lg:flex lg:justify-between lg:gap-6'>
    <div className='lg:w-48 lg:h-48 lg:min-w-48'>
      <Link className='block lg:w-full lg:h-full lg:relative' to={`/hospitals/${hospital.permalink}`}>
        <img
          className='mx-auto max-h-40 lg:max-h-full lg:my-auto lg:absolute lg:top-1/2 lg:bottom-1/2'
          src={hospital.image ? hospital.image : undefined}
          alt={`${hospital.name}`}
        />
      </Link>
    </div>

    <div className='lg:text-left lg:flex-auto'>
      <div className='mt-4'>
        <Link className='text-orange text-3xl' to={`/hospitals/${hospital.permalink}`}>
          {hospital.name}
        </Link>
      </div>

      <div className='mt-4 text-gray-500'>
        <div>{hospital.address}</div>
        <div>
          {hospital.city}, {hospital.state.code}
        </div>
      </div>
      {hospital.rating > 0 && (
        <div className='mt-4'>
          <Link className='text-orange' to={`/hospitals/${hospital.permalink}`}>
            Read Reviews
          </Link>
        </div>
      )}
    </div>

    <div>
      <div className='mt-4'>
        <HospitalFiveFaceRating hospitalID={hospital.id} rating={hospital.rating} />
      </div>

      {hospital.rating > 0 && (
        <div className='mt-4'>
          <Rating score={hospital.rating} />
          <div className='text-xs text-gray-500'>
            {hospital.reviewCount} review{hospital.reviewCount && hospital.reviewCount > 1 ? 's' : ''}
          </div>
        </div>
      )}

      <div className='lg:flex lg:gap-3'>
        <div className='mt-4'>
          <Link
            className='bg-orange block w-full font-semibold text-md rounded px-4 py-2 text-white whitespace-nowrap'
            to={`/review-hospital/${hospital.id}`}>
            Review It
          </Link>
        </div>

        <div className='mt-4'>
          <Link
            className='bg-dark-blue block w-full font-semibold text-md rounded px-4 py-2 text-white whitespace-nowrap'
            to={`/hospitals/${hospital.permalink}`}>
            Learn More
          </Link>
        </div>
      </div>
    </div>
  </li>
)

//-----------------------------------------------------------------------------
// MAIN COMPONENT
//-----------------------------------------------------------------------------

const Hospitals: React.FC = () => {
  const [hospitalResults, setHospitalResults] = useState<Hospital[]>([])
  const [totalResults, setTotalResults] = useState(0)
  const [numberShowing, setNumberShowing] = useState(0)
  const [initialLoad, setInitialLoad] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [searchErrorMessage, setSearchErrorMessage] = useState('')
  const [term, setTerm] = useState('')
  const searchStateParam = getHashParamValueFromUrl<string>('state')
  const initSearchState: string | undefined = useMemo(
    () =>
      searchStateParam
        ? Object.entries(USStates).find(
            ([eachStateName, eachStateCode]) =>
              searchStateParam.replace(' ', '').toLowerCase() === eachStateName.toLowerCase() ||
              searchStateParam.toLowerCase() === eachStateCode.toLowerCase(),
          )?.[0]
        : undefined,
    [searchStateParam],
  )
  const searchCityParam = getHashParamValueFromUrl<string>('city')
  const initSearchCity: string | undefined = searchCityParam || undefined
  const [cityStateSearchTerm, setCityStateSearchTerm] = useState(
    [initSearchCity, initSearchState].filter(Boolean).join(', '),
  )
  const [autoCompleteCityStateTerm, setAutoCompleteCityStateTerm] = useState('')
  const [autoCompleteTerm, setAutoCompleteTerm] = useState('')
  const [autoCompleteCityStateResults, setAutoCompleteCityStateResults] = useState<
    { city: string; state: USStates; stateName: string }[]
  >([])
  const [autoCompleteResults, setAutoCompleteResults] = useState<Hospital[]>([])
  const [sortValue, setSortValue] = useState('highRating')
  const searchResultContainerRef = useRef<HTMLDivElement>(null)

  const hospitalService = new HospitalService()

  const setSortAndUpdatePage = (sortValue: string) => {
    setSortValue(sortValue)
    setPage(1)
  }

  const setCityStateTermAndUpdatePage = (newCityStateTerm: string) => {
    setCityStateSearchTerm(newCityStateTerm)
    setPage(1)
  }

  const setTermAndUpdatePage = (newTerm: string) => {
    setTerm(newTerm)
    setPage(1)
  }

  useEffect(() => {
    setSearchErrorMessage('')
  }, [])

  useEffect(() => {
    setIsLoading(true)
    setSearchErrorMessage('')

    const cityStateParts = cityStateSearchTerm?.split(',')
    const stateString = !cityStateParts.length ? undefined : cityStateParts[cityStateParts.length - 1].trim()
    const stateCode = stateString
      ? Object.entries(USStates).find(
          ([eachStateName, eachStateCode]) =>
            stateString.replace(' ', '').toLowerCase() === eachStateName.toLowerCase() ||
            stateString.toLowerCase() === eachStateCode.toLowerCase(),
        )?.[1]
      : undefined
    const city = cityStateParts.length > 1 ? cityStateParts.slice(0, -1).join(',').trim() : undefined

    addHashParamToUrl('state', stateCode || '')
    addHashParamToUrl('city', city || '')

    hospitalService
      .search({ page, term, state: stateCode, city, sort: sortValue })
      .then((results) => {
        setIsLoading(false)
        setInitialLoad(false)
        setHospitalResults(results.hospitals)
        setTotalResults(results.totalResults)
        setNumberShowing(results.hospitals.length)
        setPage(results.currentPage)
        setTotalPages(results.totalPages)
        if (!initialLoad) {
          scrollToResults()
        }
      })
      .catch((err) => {
        setSearchErrorMessage(err)
        console.error(err)
        setIsLoading(false)
        scrollToResults()
      })
  }, [page, cityStateSearchTerm, sortValue, term])

  useEffect(() => {
    if (!initialLoad) {
      const cityStateParts = cityStateSearchTerm?.split(',')
      const stateString = !cityStateParts.length ? undefined : cityStateParts[cityStateParts.length - 1].trim()
      const stateCode = stateString
        ? Object.entries(USStates).find(
            ([eachStateName, eachStateCode]) =>
              stateString.replace(' ', '').toLowerCase() === eachStateName.toLowerCase() ||
              stateString.toLowerCase() === eachStateCode.toLowerCase(),
          )?.[1]
        : undefined
      const city = cityStateParts.length > 1 ? cityStateParts.slice(0, -1).join(',').trim() : undefined

      hospitalService
        .search({ page: 1, term: autoCompleteTerm, state: stateCode, city, sort: 'az', autocomplete: true })
        .then((results) => {
          setAutoCompleteResults(results.hospitals)
        })
        .catch(() => {
          // likely just a cancel of a previous request
        })
    } else {
      setAutoCompleteResults([])
    }
  }, [autoCompleteTerm])

  useEffect(() => {
    if (!initialLoad && !!autoCompleteCityStateTerm) {
      hospitalService
        .searchCityState({ searchTerm: autoCompleteCityStateTerm, limit: 12 })
        .then((results) => {
          setAutoCompleteCityStateResults(results)
        })
        .catch(() => {
          // likely just a cancel of a previous request
        })
    } else {
      setAutoCompleteCityStateResults([])
    }
  }, [autoCompleteCityStateTerm])

  const scrollToResults = () => {
    if (searchResultContainerRef && searchResultContainerRef.current)
      scrollIntoView(searchResultContainerRef.current, 0)
  }

  return (
    <div id='page-root' className='h-screen overflow-auto relative'>
      <TopLogo />

      <HeroSection />

      <div className='px-4 md:pt-20 max-w-xl lg:max-w-6xl mx-auto relative'>
        <Filter
          currentCityStateSearchTerm={cityStateSearchTerm}
          currentSearchTerm={term}
          setNewCityStateSearchTerm={setCityStateTermAndUpdatePage}
          setNewSearchTerm={setTermAndUpdatePage}
          setAutoCompleteCityStateTerm={setAutoCompleteCityStateTerm}
          setAutoCompleteTerm={setAutoCompleteTerm}
          autoCompleteCityStateResults={autoCompleteCityStateResults}
          autoCompleteResults={autoCompleteResults}
        />

        <div className='search-results' ref={searchResultContainerRef}>
          <Sort
            totalResults={totalResults}
            sortValue={sortValue}
            setSortValue={setSortAndUpdatePage}
            numberShowing={numberShowing}
            page={page}
          />

          {searchErrorMessage.length > 0 && <ErrorView>{searchErrorMessage}</ErrorView>}

          {!(hospitalResults.length > 0) && (
            <div className='text-center my-12 lg:mt-4'>
              <div className='mb-2'>No hospitals found matching your search.</div>
              <a
                href='https://scrubstr.com/add-hospital-request/'
                target='_blank'
                rel='noreferrer'
                className='block px-4 py-4 rounded-sm text-white font-semibold uppercase'
                style={{ background: '#303c6c' }}>
                Missing Hospital?
              </a>
            </div>
          )}
          {hospitalResults.length > 0 && (
            <div className='my-12 lg:mt-4'>
              <ul className='space-y-8'>{hospitalResults.map(HospitalListItem)}</ul>
            </div>
          )}
        </div>

        <Paging totalPages={totalPages} currentPage={page} setPage={setPage} scrollToResults={scrollToResults} />

        {isLoading && <Loading />}
      </div>

      <div className='mt-12'>
        <Footer />
      </div>

      <BottomNav />
    </div>
  )
}

export default Hospitals
