import React from 'react'
import { useLocation } from 'react-router-dom'
import { removeTrailingSlash } from '../utils'

export const Footer: React.FC = () => {
  const thisPage = removeTrailingSlash(useLocation().pathname) // This should return just the string of the subpath we're on
  const agencyDetailPage = thisPage.match(/agencies\/\d*/) // we know agency detail page will have a number after agencies/
  const hospitalDetailPage = thisPage.match(/hospitals\/\d*/) // we know hospital detail page will have a number after hospitals/
  return (
    <div>
      {!agencyDetailPage && !hospitalDetailPage && (
        <div className='text-center pt-20 pb-20 leading-loose' style={{ background: '#ebf6f8' }}>
          <div className='max-w-xl lg:max-w-6xl mr-auto ml-auto lg:px-4'>
            {(thisPage === '/agencies' || thisPage === '/hospitals') && (
              <div>
                <h2 className='font-semibold text-4xl mb-4'>Stay Updated</h2>
                <h3 className='text-xl mb-6 font-normal'>
                  Let us keep you informed on the latest Scrubstr News and Updates.
                </h3>
              </div>
            )}
            {thisPage !== '/agencies' && thisPage !== '/hospitals' && (
              <div>
                <h2 className='font-semibold text-4xl mb-4'>
                  Scrubstr reviews give you actionable, objective information on hospitals in your area.
                </h2>
                <h3 className='text-lg mb-6'>
                  Start your next assignment with confidence and a clear picture of what you are signing up for.
                </h3>
              </div>
            )}
          </div>
          {(thisPage === '/agencies' || thisPage === '/hospitals') && (
            <a
              href='https://scrubstr.com/contact'
              className='text-white px-5 py-3 text-xl font-semibold rounded-sm'
              style={{ background: '#f4976c' }}>
              Subscribe
            </a>
          )}
          {thisPage !== '/agencies' && thisPage !== '/hospitals' && (
            <a
              href='/register'
              className='text-white px-4 py-2 text-lg font-semibold rounded-sm'
              style={{ background: '#f4976c' }}>
              Get Started
            </a>
          )}
        </div>
      )}
      <div>
        <div className='md:flex justify-center gap-16 pt-20 pb-20'>
          <div className='mb-4 md:mb-0'>
            <div className='mx-auto' style={{ width: '150px' }}>
              <span>
                <img
                  decoding='async'
                  loading='lazy'
                  src='https://scrubstr.com/wp-content/uploads/2020/12/HQ2-2.png'
                  alt=''
                  title='HQ2'
                />
              </span>
            </div>
          </div>
          <div className='mt-auto mb-auto'>
            <div className='font-semibold text-xl text-center md:text-left'>
              <p className='mb-4'>
                <a style={{ color: '#303c6c' }} href='/hospitals/' title='Hospital Reviews'>
                  Hospital Reviews
                </a>
              </p>
              <p className='mb-4'>
                <a style={{ color: '#303c6c' }} href='/agencies/' title='Agency Reviews'>
                  Agency Reviews
                </a>
              </p>
              <p>
                <a style={{ color: '#303c6c' }} href='/tools/' title='Tools'>
                  Tools
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className='md:flex justify-around py-4' style={{ background: '#3333330f' }}>
          <div className='mb-8 md:mb-0'>
            <div className='text-center'>© 2020 scrubstr.io</div>
          </div>
          <div></div>
          <div>
            <ul className='flex justify-evenly gap-4'>
              <li>
                <a href='http://www.facebook.com/scrubstr' title='Follow on Facebook' target='_blank' rel='noreferrer'>
                  <svg
                    version='1.1'
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 533.3 529.8'>
                    <path
                      fill='#303c6c'
                      d='M533.3,266.4c0-147.3-119.4-266.7-266.7-266.7C119.4-0.3,0,119.1,0,266.4c0,133.1,97.5,243.4,225,263.4V343.4 h-67.7v-77.1H225v-58.8c0-66.8,39.8-103.8,100.7-103.8c29.2,0,59.7,5.2,59.7,5.2v65.6h-33.6c-33.1,0-43.5,20.6-43.5,41.6v50h74 l-11.8,77.1h-62.1v186.3C435.8,509.8,533.3,399.5,533.3,266.4'
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href='https://www.instagram.com/scrubstr.official/'
                  title='Follow on Instagram'
                  target='_blank'
                  rel='noreferrer'>
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 512 512'>
                    <path
                      fill='#303c6c'
                      d='M96.1,23.2c-16.2,6.3-29.9,14.7-43.6,28.4C38.8,65.2,30.4,79,24.1,95.1c-6.1,15.6-10.2,33.5-11.4,59.7    c-1.2,26.2-1.5,34.6-1.5,101.4s0.3,75.2,1.5,101.4c1.2,26.2,5.4,44.1,11.4,59.7c6.3,16.2,14.7,29.9,28.4,43.6    c13.7,13.7,27.4,22.1,43.6,28.4c15.6,6.1,33.5,10.2,59.7,11.4c26.2,1.2,34.6,1.5,101.4,1.5c66.8,0,75.2-0.3,101.4-1.5    c26.2-1.2,44.1-5.4,59.7-11.4c16.2-6.3,29.9-14.7,43.6-28.4c13.7-13.7,22.1-27.4,28.4-43.6c6.1-15.6,10.2-33.5,11.4-59.7    c1.2-26.2,1.5-34.6,1.5-101.4s-0.3-75.2-1.5-101.4c-1.2-26.2-5.4-44.1-11.4-59.7C484,79,475.6,65.2,462,51.6    c-13.7-13.7-27.4-22.1-43.6-28.4c-15.6-6.1-33.5-10.2-59.7-11.4c-26.2-1.2-34.6-1.5-101.4-1.5s-75.2,0.3-101.4,1.5    C129.6,12.9,111.7,17.1,96.1,23.2z M356.6,56c24,1.1,37,5.1,45.7,8.5c11.5,4.5,19.7,9.8,28.3,18.4c8.6,8.6,13.9,16.8,18.4,28.3    c3.4,8.7,7.4,21.7,8.5,45.7c1.2,25.9,1.4,33.7,1.4,99.4s-0.3,73.5-1.4,99.4c-1.1,24-5.1,37-8.5,45.7c-4.5,11.5-9.8,19.7-18.4,28.3    c-8.6,8.6-16.8,13.9-28.3,18.4c-8.7,3.4-21.7,7.4-45.7,8.5c-25.9,1.2-33.7,1.4-99.4,1.4s-73.5-0.3-99.4-1.4    c-24-1.1-37-5.1-45.7-8.5c-11.5-4.5-19.7-9.8-28.3-18.4c-8.6-8.6-13.9-16.8-18.4-28.3c-3.4-8.7-7.4-21.7-8.5-45.7    c-1.2-25.9-1.4-33.7-1.4-99.4s0.3-73.5,1.4-99.4c1.1-24,5.1-37,8.5-45.7c4.5-11.5,9.8-19.7,18.4-28.3c8.6-8.6,16.8-13.9,28.3-18.4    c8.7-3.4,21.7-7.4,45.7-8.5c25.9-1.2,33.7-1.4,99.4-1.4S330.7,54.8,356.6,56z'
                    />
                    <path
                      fill='currentColor'
                      d='M130.9,256.3c0,69.8,56.6,126.3,126.3,126.3s126.3-56.6,126.3-126.3S327,130,257.2,130    S130.9,186.5,130.9,256.3z M339.2,256.3c0,45.3-36.7,82-82,82s-82-36.7-82-82c0-45.3,36.7-82,82-82S339.2,211,339.2,256.3z'
                    />
                    <circle fill='currentColor' cx='388.6' cy='125' r='29.5' />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href='https://www.pinterest.com/scrubstr'
                  title='Follow on Pinterest'
                  target='_blank'
                  rel='noreferrer'>
                  <svg
                    version='1.1'
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 144 144'
                    enableBackground='new 0 0 144 144'
                    xmlSpace='preserve'>
                    <g>
                      <g>
                        <path
                          fill='#303c6c'
                          d='M71.9,5.4C35.1,5.4,5.3,35.2,5.3,72c0,28.2,17.5,52.3,42.3,62c-0.6-5.3-1.1-13.3,0.2-19.1c1.2-5.2,7.8-33.1,7.8-33.1s-2-4-2-9.9c0-9.3,5.4-16.2,12-16.2c5.7,0,8.4,4.3,8.4,9.4c0,5.7-3.6,14.3-5.5,22.2c-1.6,6.6,3.3,12,9.9,12c11.8,0,20.9-12.5,20.9-30.5c0-15.9-11.5-27.1-27.8-27.1c-18.9,0-30.1,14.2-30.1,28.9c0,5.7,2.2,11.9,5,15.2c0.5,0.7,0.6,1.2,0.5,1.9c-0.5,2.1-1.6,6.6-1.8,7.5c-0.3,1.2-1,1.5-2.2,0.9c-8.3-3.9-13.5-16-13.5-25.8c0-21,15.3-40.3,44-40.3c23.1,0,41,16.5,41,38.4c0,22.9-14.5,41.4-34.5,41.4c-6.7,0-13.1-3.5-15.3-7.6c0,0-3.3,12.7-4.1,15.8c-1.5,5.8-5.6,13-8.3,17.5c6.2,1.9,12.8,3,19.7,3c36.8,0,66.6-29.8,66.6-66.6C138.5,35.2,108.7,5.4,71.9,5.4z'
                        />
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
