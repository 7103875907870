// Button Component to scroll to top of page

import React, { useEffect } from 'react'

// Scroll to top button. Place this component whereever you want the button to use as the "top of page" anchor
export default function ScrollToTop() {
  // Add an event listener that will show the button when the user scrolls down from the top of the page
  useEffect(() => {
    document.body.querySelector('#page-root')?.addEventListener(
      'scroll',
      () => {
        handleScroll()
      },
      false,
    )

    return () => document.body.removeEventListener('scroll', handleScroll)
  }, [])
  const handleScroll = () => {
    const scrollBtn = document.querySelector('#scroll-to-top-btn')
    if (scrollBtn) {
      const pageRoot = document.body.querySelector('#page-root')
      // If the user has scrolled down more than 300px, show the button
      if (pageRoot && pageRoot?.scrollTop > 300) {
        scrollBtn.classList.remove('opacity-0', '-right-10')
        scrollBtn.classList.add('right-0')
      }
      // If the user has scrolled back up to the top, hide the button
      else {
        scrollBtn.classList.remove('right-0')
        scrollBtn.classList.add('opacity-0', '-right-10')
      }
    }
  }
  const handleClick = () => {
    const anchor = document.querySelector('#back-to-top-anchor')

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  return (
    <div>
      <div id='back-to-top-anchor' />
      <div
        onClick={handleClick}
        role='presentation'
        id='scroll-to-top-btn'
        className='opacity-0 -right-10 cursor-pointer transition-all duration-500 fixed bottom-40 rounded-l-md text-white p-1.5 bg-gray-500 bg-opacity-70'>
        {/* Up arrow svg */}
        <svg xmlns='http://www.w3.org/2000/svg' height='24' width='24' fill='currentColor'>
          <path d='m7.4 15.375-1.4-1.4 6-6 6 6-1.4 1.4-4.6-4.6Z' />
        </svg>
      </div>
    </div>
  )
}
