import React, { useEffect, useState } from 'react'
import { AppState, RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react'
import { useHistory, useLocation } from 'react-router-dom'
import { isPlatform, useIonAlert } from '@ionic/react'
import { Browser } from '@capacitor/browser'

import { UserDTO } from '../models/UserDTO.model'
import { NursingSpecializations } from '../models/NursingSpecializations.model'
import { PositionTitles } from '../models/PositionTitles.model'

import UserService from '../services/UserService/UserService'

import { TopLogo } from '../components/TopLogo'
import { Loading } from '../components/Loading'
import { Footer } from '../components/Footer'
import { BottomNav } from '../components/BottomNav'
import { LogoutButton } from '../components/LogoutButton'

const Profile = (): JSX.Element => {
  const isMobileDevice = isPlatform('android') || isPlatform('ios')
  const [presentAlert] = useIonAlert()
  const [presentAlertExtraConfirm] = useIonAlert()
  const [presentAlertDeleted] = useIonAlert()
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const [userDetails, setUserDetails] = useState<UserDTO>()
  const [formUserDetails, setFormUserDetails] = useState<UserDTO>()
  const [isEditing, setIsEditing] = useState(false)
  const [editErrorMessage, setEditErrorMessage] = useState('')
  const [deleteErrorMessage, setDeleteErrorMessage] = useState('')
  const [isSavingUser, setIsSavingUser] = useState(false)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (!isAuthenticated) {
      history.replace(`/login/redirect/${encodeURIComponent(location.pathname)}`)
    }
  }, [isAuthenticated])

  useEffect(() => {
    // format the user data
    if (isAuthenticated && user) {
      const formattedUser = UserService.formatUser(user)

      setUserDetails(formattedUser)
      setFormUserDetails(formattedUser)
    }
  }, [isAuthenticated, user])

  const handleEdit = async () => {
    setEditErrorMessage('')
    if (formUserDetails && userDetails) {
      setIsSavingUser(true)

      try {
        // check if the user fields was changed and if so, send a request to our API to update the user
        const changedUserFields: Partial<Record<'name' | 'position_title' | 'nursing_specialization', string>> = {}
        if (formUserDetails.name !== userDetails.name) {
          changedUserFields.name = formUserDetails.name
        }
        if (formUserDetails.position_title !== userDetails.position_title) {
          changedUserFields.position_title = formUserDetails.position_title
        }
        if (formUserDetails.nursing_specialization !== userDetails.nursing_specialization) {
          changedUserFields.nursing_specialization = formUserDetails.nursing_specialization
        }
        if (Object.keys(changedUserFields).length) {
          const newUserDetails = await UserService.updateUser(userDetails.id, changedUserFields)

          // update the user in the Auth0 session by forcing a login (which will be instant since they are already logged in)
          let loginParams: RedirectLoginOptions<AppState> = {
            authorizationParams: { scope: 'profile email read:current_user' },
            appState: { redirectUrl: location.pathname },
          }
          if (isMobileDevice) {
            loginParams = {
              ...loginParams,
              async openUrl(url) {
                // Redirect using Capacitor's Browser plugin
                await Browser.open({
                  url,
                  windowName: '_self',
                })
              },
            }
          }

          await loginWithRedirect(loginParams)

          setUserDetails(newUserDetails)
          setFormUserDetails(newUserDetails)
        }

        setIsEditing(false)
      } catch (e) {
        console.error(e)
        let errorMessage = 'There was an error updating your profile. Please try again or contact support.'
        const axiosError = e as { status: number; data: { message: string } }
        if ([400, 403].includes(axiosError?.status) && axiosError?.data?.message) {
          errorMessage = axiosError.data.message
        }
        setEditErrorMessage(errorMessage)
      }
      setIsSavingUser(false)
    }
  }

  const handleDelete = async () => {
    setDeleteErrorMessage('')
    if (formUserDetails && userDetails) {
      setIsSavingUser(true)

      try {
        await UserService.deleteUser(userDetails.id)

        presentAlertDeleted({
          header: "Your data has been deleted. You will be logged out now. We're sorry to see you go.",
        })

        setTimeout(() => {
          history.push('/logout')
        }, 3000)
      } catch (e) {
        console.error(e)
        setDeleteErrorMessage('There was an error deleting your profile. Please try again or contact support.')
      }
      setIsSavingUser(false)
    }
  }

  return (
    <div id='page-root' className='h-screen overflow-auto relative'>
      <TopLogo />

      <div className='relative'>
        {(isLoading || isSavingUser) && <Loading />}
        <div className='p-2 mt-6'>
          {deleteErrorMessage && (
            <div className='validation-error text-red-500 mb-2' tabIndex={-1}>
              {deleteErrorMessage}
            </div>
          )}
          {isAuthenticated && userDetails && (
            <>
              <div className='border border-solid border-gray-300 rounded-sm p-4 max-w-screen-md m-auto'>
                <div className='mb-4'>
                  {userDetails.picture ? (
                    <img
                      className='max-h-24 max-w-24 rounded-full m-auto'
                      src={userDetails.picture}
                      alt='profile avatar'
                      referrerPolicy='no-referrer'
                    />
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-24 w-24 p-2 m-auto'
                      style={{ color: 'rgb(48, 60, 108)' }}
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z'
                      />
                    </svg>
                  )}
                </div>

                {isEditing && formUserDetails ? (
                  <>
                    {userDetails.isSocial ? (
                      <div className='mb-4'>
                        <label className='block text-lg text-dark-blue'>Name</label>
                        <div className='mt-2 w-full py-2'>{userDetails.name}</div>
                      </div>
                    ) : (
                      <div className='mb-4'>
                        <label className='block text-lg text-dark-blue'>Name</label>
                        <input
                          name='name-input'
                          type='text'
                          className='mt-2 w-full border border-solid border-gray-300 p-2 rounded'
                          onChange={(evt) => {
                            setFormUserDetails({
                              ...formUserDetails,
                              name: evt.target.value,
                            })
                          }}
                          onKeyUp={(evt) => {
                            if (evt.key === 'Enter') {
                              handleEdit()
                            }
                          }}
                          value={formUserDetails.name}
                        />
                      </div>
                    )}

                    <div className='mb-4'>
                      <label className='block text-lg text-dark-blue'>Email</label>
                      <div className='mt-2 w-full py-2'>{userDetails.email}</div>
                    </div>

                    <div className='mb-4'>
                      <label className='block text-lg text-dark-blue'>Position / Title</label>
                      <select
                        name='position_title-input'
                        className='w-full mt-2 py-2 px-2 rounded border border-solid border-gray-300'
                        onChange={(evt) => {
                          setFormUserDetails({
                            ...formUserDetails,
                            position_title: evt.target.value,
                          })
                        }}
                        defaultValue={formUserDetails.position_title}>
                        <option value=''>-Select-</option>
                        {Object.values(PositionTitles).map((positionTitle) => (
                          <option key={positionTitle} value={positionTitle}>
                            {positionTitle}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className='mb-4'>
                      <label className='block text-lg text-dark-blue'>Nursing Specialization</label>
                      <select
                        name='nursing_specialization-input'
                        className='w-full mt-2 py-2 px-2 rounded border border-solid border-gray-300'
                        onChange={(evt) => {
                          setFormUserDetails({
                            ...formUserDetails,
                            nursing_specialization: evt.target.value,
                          })
                        }}
                        defaultValue={formUserDetails.nursing_specialization}>
                        <option value=''>-Select-</option>
                        {Object.values(NursingSpecializations).map((nursingSpecialization) => (
                          <option key={nursingSpecialization} value={nursingSpecialization}>
                            {nursingSpecialization}
                          </option>
                        ))}
                      </select>
                    </div>
                    {editErrorMessage && (
                      <div className='validation-error text-red-500' tabIndex={-1}>
                        {editErrorMessage}
                      </div>
                    )}

                    <button
                      className={`mt-8 w-full font-semibold bg-dark-blue text-white rounded py-4`}
                      onClick={() => handleEdit()}>
                      Save
                    </button>

                    <button className={`w-full font-semibold rounded py-4`} onClick={() => setIsEditing(false)}>
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <div className='mb-4'>
                      <label className='block text-lg text-dark-blue'>Name</label>
                      <div className='mt-2 w-full py-2'>{userDetails.name}</div>
                    </div>

                    <div className='mb-4'>
                      <label className='block text-lg text-dark-blue'>Email</label>
                      <div className='mt-2 w-full py-2'>{userDetails.email}</div>
                    </div>

                    <div className='mb-4'>
                      <label className='block text-lg text-dark-blue'>Position / Title</label>
                      <div className='mt-2 w-full py-2'>{userDetails.position_title}</div>
                    </div>

                    <div className='mb-4'>
                      <label className='block text-lg text-dark-blue'>Nursing Specialization</label>
                      <div className='mt-2 w-full py-2'>{userDetails.nursing_specialization}</div>
                    </div>

                    <button
                      className={`w-full font-semibold rounded py-4`}
                      onClick={() => {
                        setEditErrorMessage('')
                        setIsEditing(true)
                      }}>
                      Edit
                    </button>
                  </>
                )}
              </div>
              <div className='flex justify-between mt-4 px-4 max-w-screen-md m-auto'>
                <button
                  className='text-gray-500 text-sm'
                  onClick={() =>
                    presentAlert({
                      header: 'Are you sure you wish to delete your account and all of your data?',
                      buttons: [
                        {
                          text: 'Cancel',
                          role: 'cancel',
                        },
                        {
                          text: 'Confirm',
                          role: 'confirm',
                          handler: () => {
                            presentAlertExtraConfirm({
                              header: "Let's make sure you really want to do this.",
                              buttons: [
                                {
                                  text: 'Nevermind',
                                  role: 'cancel',
                                },
                                {
                                  text: 'Very sure',
                                  role: 'confirm',
                                  handler: () => {
                                    handleDelete()
                                  },
                                },
                              ],
                            })
                          },
                        },
                      ],
                    })
                  }>
                  Delete My Data
                </button>
                <LogoutButton className='text-gray-500 text-sm' />
              </div>
            </>
          )}
        </div>
      </div>

      <div className='mt-12'>
        <Footer />
      </div>

      <BottomNav />
    </div>
  )
}

export default Profile
