import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Browser } from '@capacitor/browser'
import { isPlatform } from '@ionic/react'
import { useHistory } from 'react-router-dom'

const Logout = (): JSX.Element => {
  const isMobileDevice = isPlatform('android') || isPlatform('ios')
  const { logout } = useAuth0()
  const history = useHistory()
  // have to hardcode the auth0 prod domain in order to bypass paying a ton for Ionic since env support is a premium feature
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || 'scrubstr.us.auth0.com'

  if (isMobileDevice) {
    // Since on a mobile device, the logout happens in a separate browser window leaving the app in its previous state,
    // we need to send them to the default route so that when they return after logging in, they are not still on a potentially private route
    history.push(`/`)
  }
  logout(
    !isMobileDevice
      ? { logoutParams: { returnTo: window.location.origin } }
      : {
          logoutParams: {
            returnTo: `com.scrubstr.app://${domain}/capacitor/com.scrubstr.app/callback`,
          },
          async openUrl(url) {
            // Redirect using Capacitor's Browser plugin
            await Browser.open({
              url,
              windowName: '_self',
            })
          },
        },
  )

  return <div>Logging out...</div>
}

export default Logout
