import { User } from '@auth0/auth0-react'

import { UserDTO } from '../../models/UserDTO.model'

import ScrubstrApiQueryService from '../ScrubstrApiQueryService/ScrubstrApiQueryService'

export default class UserService {
  /**
   * @description Updates the user
   *
   * @param id
   * @param data
   * @param token
   *
   * @returns Promise<void>
   */
  public static async updateUser(
    id: string,
    data: Partial<Record<'name' | 'position_title' | 'nursing_specialization', string>>,
  ): Promise<UserDTO> {
    const scrubstrApiQueryService = new ScrubstrApiQueryService()
    return await scrubstrApiQueryService.patch<UserDTO>({
      endpoint: `user/${encodeURIComponent(id)}`,
      data,
    })
  }

  /**
   * @description Removes the user from the system and deletes all their data
   *
   * @param id
   * @param token
   *
   * @returns Promise<void>
   */
  public static async deleteUser(id: string): Promise<void> {
    const scrubstrApiQueryService = new ScrubstrApiQueryService()
    await scrubstrApiQueryService.delete<boolean>({
      endpoint: `user/${encodeURIComponent(id)}`,
    })
  }

  /**
   * @description Takes in a user object fetched from the Auth0 hook and formats it to match the UserDTO model
   *
   * @param user
   *
   * @returns UserDTO
   */
  public static formatUser(user: User): UserDTO {
    return {
      id: user.sub || '',
      isSocial: !!user['https://app.scrubstr.com/idp']?.find((idp: { isSocial: boolean }) => idp.isSocial),
      name: user.name || '',
      email: user.email || '',
      picture: user.picture || '',
      position_title: user['https://app.scrubstr.com/position_title'] || '',
      nursing_specialization: user['https://app.scrubstr.com/nursing_specialization'] || '',
      permissions: Array.isArray(user['https://app.scrubstr.com/permissions'])
        ? user['https://app.scrubstr.com/permissions']
        : [],
    }
  }
}
