import React from 'react'
import { Link } from 'react-router-dom'
import { BottomNav } from '../components/BottomNav'
import { Footer } from '../components/Footer'
import { TopLogo } from '../components/TopLogo'

const Tools: React.FC = () => {
  return (
    <div id='page-root' className='h-screen overflow-auto relative'>
      <TopLogo />
      <div className='px-4 max-w-xl lg:max-w-6xl mx-auto py-60'>
        <Link
          to='/income-calculator'
          className='block bg-dark-blue font-semibold text-md rounded px-4 py-2 mb-6 text-white text-center'>
          Income Calculator
        </Link>
        <Link
          to='/gsa-calculator'
          className='block bg-dark-blue font-semibold text-md rounded px-4 py-2 mb-6 text-white text-center'>
          GSA Calculator
        </Link>
      </div>

      <div className='mt-12'>
        <Footer />
      </div>

      <BottomNav />
    </div>
  )
}

export default Tools
