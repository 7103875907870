import React from 'react'
import { Link } from 'react-router-dom'

type HospitalFiveFaceRatingProps = {
  hospitalID?: number
  rating: number
}

export const HospitalFiveFaceRating = ({ hospitalID, rating }: HospitalFiveFaceRatingProps): JSX.Element => {
  let faceDefaultBG = '/assets/faces/scrubstr-faces-3.png'
  let faceColorBG = '/assets/faces/scrubstr-faces-3-color.png'
  if (rating <= 1) {
    faceDefaultBG = '/assets/faces/scrubstr-faces-1.png'
    faceColorBG = '/assets/faces/scrubstr-faces-1-color.png'
  }
  if (rating > 1 && rating <= 2) {
    faceDefaultBG = '/assets/faces/scrubstr-faces-2.png'
    faceColorBG = '/assets/faces/scrubstr-faces-2-color.png'
  }
  if (rating > 2 && rating <= 3) {
    faceDefaultBG = '/assets/faces/scrubstr-faces-3.png'
    faceColorBG = '/assets/faces/scrubstr-faces-3-color.png'
  }
  if (rating > 3 && rating <= 4) {
    faceDefaultBG = '/assets/faces/scrubstr-faces-4.png'
    faceColorBG = '/assets/faces/scrubstr-faces-4-color.png'
  }
  if (rating > 4) {
    faceDefaultBG = '/assets/faces/scrubstr-faces-5.png'
    faceColorBG = '/assets/faces/scrubstr-faces-5-color.png'
  }

  return (
    <div className='scrubstr-five-face-rating-container'>
      {!(rating > 0) && hospitalID && (
        <Link to={`/review-hospital/${hospitalID}`} className='text-orange text-2xl'>
          Be the first to review!
        </Link>
      )}
      {rating > 0 && (
        <ul className='scrubstr-five-face-rating <?php echo $faceClass; ?>'>
          <li
            style={{ backgroundImage: `url(${faceDefaultBG})` }}
            className={`scrubstr-face-rating-face ${
              rating > 0.5 ? 'scrubstr-filled-face' : rating > 0 ? 'scrubstr-half-filled-face' : ''
            }`}>
            <div style={{ backgroundImage: `url(${faceColorBG})` }}></div>
          </li>
          <li
            style={{ backgroundImage: `url(${faceDefaultBG})` }}
            className={`scrubstr-face-rating-face ${
              rating > 1.5 ? 'scrubstr-filled-face' : rating > 1 ? 'scrubstr-half-filled-face' : ''
            }`}>
            <div style={{ backgroundImage: `url(${faceColorBG})` }}></div>
          </li>
          <li
            style={{ backgroundImage: `url(${faceDefaultBG})` }}
            className={`scrubstr-face-rating-face ${
              rating > 2.5 ? 'scrubstr-filled-face' : rating > 2 ? 'scrubstr-half-filled-face' : ''
            }`}>
            <div style={{ backgroundImage: `url(${faceColorBG})` }}></div>
          </li>
          <li
            style={{ backgroundImage: `url(${faceDefaultBG})` }}
            className={`scrubstr-face-rating-face ${
              rating > 3.5 ? 'scrubstr-filled-face' : rating > 3 ? 'scrubstr-half-filled-face' : ''
            }`}>
            <div style={{ backgroundImage: `url(${faceColorBG})` }}></div>
          </li>
          <li
            style={{ backgroundImage: `url(${faceDefaultBG})` }}
            className={`scrubstr-face-rating-face ${
              rating > 4.5 ? 'scrubstr-filled-face' : rating > 4 ? 'scrubstr-half-filled-face' : ''
            }`}>
            <div style={{ backgroundImage: `url(${faceColorBG})` }}></div>
          </li>
        </ul>
      )}
    </div>
  )
}
