import axios, { CancelToken } from 'axios'
import axiosRetry from 'axios-retry'
import { AppUpdateContext } from '../../providers/AppUpdateContext'
import { AuthStateContext } from '../../providers/AuthStateContext'

export type ScrubstrApiQueryServiceGetEvent = {
  endpoint: string
  headers?: Record<string, string>
  cancelToken?: CancelToken
  params?: Record<string, string | number | undefined>
}

export default class ScrubstrApiQueryService {
  private apiUrl: string
  private apiToken: string
  private apiVersion: string

  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL || 'https://a18bvyice6.execute-api.us-east-1.amazonaws.com/prod'
    this.apiToken = process.env.REACT_APP_API_TOKEN || 'ulK9aCqDwL8Uxrc8C5V6P5jOkX2BnSDo7FlBXsdQ'
    this.apiVersion = '0.9.0' // Update this when the API version changes

    axiosRetry(axios, {
      retries: 3, // number of retries
      retryDelay: (retryCount, error) => {
        console.error(`retry attempt: ${retryCount}`)
        // Use the x-ratelimit-reset header if present
        const rateLimitReset = error?.response?.headers?.['x-ratelimit-reset']
        if (rateLimitReset && !isNaN(Number(rateLimitReset))) {
          console.error(`ratelimit reset: ${rateLimitReset}`)
          // calc the difference between the current time and the rateLimitReset time
          const currentTime: number = new Date().getTime() / 1000 // convert to seconds to match expected ratelimit reset time
          const secondsTillReset = Number(rateLimitReset) - currentTime
          console.error(`retry after: ${secondsTillReset} seconds`)
          if (secondsTillReset > 0) {
            return retryCount * secondsTillReset * 1000 + 1 // ensure we don't retry until 1 ms after the rate limit reset
          }
        }

        return retryCount * 2000 // time interval between retries default to 2 seconds
      },
      retryCondition: (error) => {
        // if retry condition is not specified, by default idempotent requests are retried
        return error?.response?.status === 429
      },
    })
  }

  /**
   * You can optionally pass a cancelToken to have requests cancelled if they come in with the same token.
   * You need to create a `const REQUEST_CANCEL_SOURCE = axios.CancelToken.source()` and then pass the output's `REQUEST_CANCEL_SOURCE.token` property to this function as the cancelToken property of the event.
   * To cancel the request, before calling this method, call the source's cancel method. `REQUEST_CANCEL_SOURCE.cancel('cancelToken triggered')`
   *
   * @param event
   * @returns a promise of the type passed to the function (if no type is passed, it will return a promise of unknown)
   */
  public async get<T>(event: ScrubstrApiQueryServiceGetEvent): Promise<T> {
    const headersToSend = this.setHeaders(event.headers)
    const response = await axios
      .get(`${this.apiUrl}${this.formatEndpoint(event.endpoint)}`, {
        headers: headersToSend,
        cancelToken: event.cancelToken,
        params: event.params,
      })
      .catch((error) => {
        if (error?.message !== 'cancelToken triggered') {
          if (axios.isAxiosError(error) && error?.response?.status === 406) {
            AppUpdateContext.triggerAppUpdate()
          } else {
            if (error.response) {
              throw error.response
            }
            throw error
          }
        }
      })
    return response?.data
  }

  public async post<T>(event: { endpoint: string; data?: unknown; headers?: Record<string, string> }): Promise<T> {
    const headersToSend = this.setHeaders(event.headers)

    const response = await axios
      .post(`${this.apiUrl}${this.formatEndpoint(event.endpoint)}`, event.data, {
        headers: headersToSend,
      })
      .catch((error) => {
        if (axios.isAxiosError(error) && error?.response?.status === 406) {
          AppUpdateContext.triggerAppUpdate()
        } else {
          if (error.response) {
            throw error.response
          }
          throw error
        }
      })
    return response?.data
  }

  public async patch<T>(event: { endpoint: string; data?: unknown; headers?: Record<string, string> }): Promise<T> {
    const headersToSend = this.setHeaders(event.headers)
    const response = await axios
      .patch(`${this.apiUrl}${this.formatEndpoint(event.endpoint)}`, event.data, {
        headers: headersToSend,
      })
      .catch((error) => {
        if (axios.isAxiosError(error) && error?.response?.status === 406) {
          AppUpdateContext.triggerAppUpdate()
        } else {
          if (error.response) {
            throw error.response
          }
          throw error
        }
      })
    return response?.data
  }

  public async delete<T>(event: { endpoint: string; headers?: Record<string, string> }): Promise<T> {
    const headersToSend = this.setHeaders(event.headers)
    const response = await axios
      .delete(`${this.apiUrl}${this.formatEndpoint(event.endpoint)}`, {
        headers: headersToSend,
      })
      .catch((error) => {
        if (axios.isAxiosError(error) && error?.response?.status === 406) {
          AppUpdateContext.triggerAppUpdate()
        } else {
          if (error.response) {
            throw error.response
          }
          throw error
        }
      })
    return response?.data
  }

  private formatEndpoint(endpoint: string): string {
    // remove leading slash
    if (!endpoint.startsWith('/')) {
      endpoint = `/${endpoint}`
    }
    return endpoint
  }

  private setHeaders(headers?: Record<string, string>): Record<string, string> {
    const updatedHeaders: Record<string, string> = {
      'Content-Type': 'application/json',
      'x-api-key': this.apiToken,
      'x-api-version': this.apiVersion,
    }

    // if logged in, add jwt to headers
    if (AuthStateContext.getToken()) {
      updatedHeaders.Authorization = `Bearer ${AuthStateContext.getToken()}`
    }

    if (headers) {
      // "override" headersToSend defaults with the provided headers
      Object.keys(headers).forEach((key) => {
        updatedHeaders[key] = headers[key]
      })
    }
    return updatedHeaders
  }
}
