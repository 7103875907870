import React, { useState } from 'react'
import startCase from 'lodash/startCase'
import camelCase from 'lodash/camelCase'
import { getObjectKeyFromValue, IntRange, scrollIntoView } from '../utils'
import { calculateCompensation, Compensation } from '../workers/compensationCalc'

import { USStates } from '../models/USStates.model'
import { months } from '../models/Months.model'

import { IncomeCalcService } from '../services/IncomeCalcService/IncomeCalcService'

import { TopLogo } from '../components/TopLogo'
import { Loading } from '../components/Loading'
import { Footer } from '../components/Footer'
import { BottomNav } from '../components/BottomNav'

type FieldValues = {
  state: USStates
  city: string
  'start-month': IntRange<1, 13>
  'start-day': number
  'start-year': number
  'hourly-rate'?: string
  'hour-amount'?: string
  'overtime-rate'?: string
  'overtime-amount'?: string
  'contracted-weekly-stipends'?: string
}

const IncomeCalc: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()
  const currentDate = new Date().getDate()
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>('')
  const [validationErrors, setValidationErrors] = useState<Record<string, boolean>>({})
  const [fieldValues, setFieldValues] = useState<FieldValues>({
    state: USStates.Alabama,
    city: '',
    'start-month': (currentMonth + 1) as IntRange<1, 13>,
    'start-day': currentDate,
    'start-year': currentYear,
  })
  const [showCalculation, setShowCalculation] = useState(false)
  const [calculation, setCalculation] = useState<Compensation | undefined>()

  const incomeCalcService = new IncomeCalcService()

  const submitCalculator = () => {
    setShowCalculation(false)
    setCalculation(undefined)

    const newValidationErrors = {} as Record<string, boolean>
    if (!fieldValues['city']) {
      newValidationErrors['city'] = true
    }

    setValidationErrors(newValidationErrors)
    if (JSON.stringify(newValidationErrors) !== JSON.stringify({})) {
      // add validation errors next to inputs and scroll to the first one
      const validationElements = document.getElementsByClassName('validation-error')
      if (validationElements.length > 0) {
        const firstValidationElement = validationElements[0]
        ;(firstValidationElement as HTMLElement)?.focus()
      }
    } else {
      setIsLoading(true)
      setSubmitErrorMessage('')
      incomeCalcService
        .get({
          state: fieldValues.state,
          city: fieldValues.city,
          startDate: `${fieldValues['start-year']}-${String(fieldValues['start-month']).padStart(2, '0')}-${String(
            fieldValues['start-day'],
          ).padStart(2, '0')}`,
        })
        .then((GSAResult) => {
          const hourlyRate = Number(fieldValues['hourly-rate'])
          const hourAmount = Number(fieldValues['hour-amount'])
          const overtimeRate = Number(fieldValues['overtime-rate'])
          const overtimeAmount = Number(fieldValues['overtime-amount'])
          const startMonth = Number(fieldValues['start-month'])
          const numberOfDays = 7

          const newCompensation = calculateCompensation({
            hourlyRate,
            hourAmount,
            overtimeRate,
            overtimeAmount,
            startMonth,
            numberOfDays,
            GSAResult,
          })

          setCalculation(newCompensation)
          setShowCalculation(true)
          setIsLoading(false)

          // scroll down to results
          const focusTrapElement = document.getElementsByClassName('calculator-focus-trap')
          if (focusTrapElement.length > 0) {
            const firstFocusTrapElement = focusTrapElement[0]
            ;(firstFocusTrapElement as HTMLElement)?.focus()
          }
        })
        .catch((err) => {
          setSubmitErrorMessage('Unable to fetch GSA data.')
          setIsLoading(false)
          console.error(err)
        })
    }
  }

  return (
    <div id='page-root' className='h-screen overflow-auto relative'>
      <TopLogo />
      <div className='px-4 max-w-xl lg:max-w-6xl mx-auto py-6 '>
        {isLoading && <Loading />}

        {!isLoading && (
          <div>
            <h1 className='mb-10 font-semibold mt-4 text-center text-2xl'>Income Calculator</h1>
            <h2 className='mb-10 font-semibold mt-4 text-center text-xl'>Get the most out of your contract</h2>
            {/* <!-- START DATE --> */}
            <div className='mt-4'>
              <div>
                <label className='font-medium'>Start Date:</label>
              </div>
              <div className='flex justify-content items-center'>
                <select
                  name='start-month'
                  className='w-full p-2 rounded border border-solid border-gray-300'
                  defaultValue={fieldValues['start-month']}
                  onChange={(evt) => {
                    setFieldValues((prevFieldValues) => ({
                      ...prevFieldValues,
                      'start-month': Number(evt.target.value) as typeof fieldValues['start-month'],
                    }))
                  }}>
                  {months.map((month, monthIndex) => {
                    return (
                      <option key={month} value={monthIndex + 1}>
                        {month}
                      </option>
                    )
                  })}
                </select>
                <select
                  name='start-day'
                  className='w-full p-2 rounded border border-solid border-gray-300'
                  defaultValue={fieldValues['start-day']}
                  onChange={(evt) => {
                    setFieldValues((prevFieldValues) => ({
                      ...prevFieldValues,
                      'start-day': Number(evt.target.value),
                    }))
                  }}>
                  {[...Array(31)].map((value: undefined, index: number) => (
                    <option key={'date_' + index} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
                <select
                  name='start-year'
                  className='w-full p-2 rounded border border-solid border-gray-300'
                  defaultValue={fieldValues['start-year']}
                  onChange={(evt) => {
                    setFieldValues((prevFieldValues) => ({
                      ...prevFieldValues,
                      'start-year': Number(evt.target.value),
                    }))
                  }}>
                  {[...Array(10)].map((value: undefined, index: number) => (
                    <option key={'year_' + (currentYear - 10 + index)} value={currentYear - 10 + index}>
                      {currentYear - 10 + index}
                    </option>
                  ))}
                  <option value={currentYear}>{currentYear}</option>
                  {[...Array(10)].map((value: undefined, index: number) => (
                    <option key={'year_' + (currentYear - 1 + index)} value={currentYear - 1 + index}>
                      {currentYear - 1 + index}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* <!-- HOURLY RATE --> */}
            <div className='mt-4'>
              <div>
                <label className='font-medium'>Hourly Rate:</label>
              </div>
              <div>
                <input
                  type='number'
                  step='.01'
                  name='hourly-rate'
                  className='w-full border border-solid border-gray-300 p-2 rounded'
                  defaultValue={fieldValues['hourly-rate']}
                  onChange={(evt) => {
                    setFieldValues((prevFieldValues) => ({
                      ...prevFieldValues,
                      'hourly-rate': evt.target.value,
                    }))
                  }}
                  onKeyPress={(evt) => {
                    if (evt.key === 'Enter') {
                      submitCalculator()
                    }
                  }}
                  onFocus={(evt) => scrollIntoView(evt.target)}
                />
              </div>
            </div>

            {/* <!-- NUMBER OF HOURS--> */}
            <div className='mt-4'>
              <div>
                <label className='font-medium'>Number of normal hours:</label>
              </div>
              <div>
                <input
                  type='number'
                  step='any'
                  name='hour-amount'
                  className='w-full border border-solid border-gray-300 p-2 rounded'
                  defaultValue={fieldValues['hour-amount']}
                  onChange={(evt) => {
                    setFieldValues((prevFieldValues) => ({
                      ...prevFieldValues,
                      'hour-amount': evt.target.value,
                    }))
                  }}
                  onKeyPress={(evt) => {
                    if (evt.key === 'Enter') {
                      submitCalculator()
                    }
                  }}
                  onFocus={(evt) => scrollIntoView(evt.target)}
                />
              </div>
            </div>

            {/* <!-- OVERTIME RATE --> */}
            <div className='mt-4'>
              <div>
                <label className='font-medium'>Overtime Rate:</label>
              </div>
              <div>
                <input
                  type='number'
                  step='.01'
                  name='overtime-rate'
                  className='w-full border border-solid border-gray-300 p-2 rounded'
                  defaultValue={fieldValues['overtime-rate']}
                  onChange={(evt) => {
                    setFieldValues((prevFieldValues) => ({
                      ...prevFieldValues,
                      'overtime-rate': evt.target.value,
                    }))
                  }}
                  onKeyPress={(evt) => {
                    if (evt.key === 'Enter') {
                      submitCalculator()
                    }
                  }}
                  onFocus={(evt) => scrollIntoView(evt.target)}
                />
              </div>
            </div>

            {/* <!-- OVERTIME HOURS --> */}
            <div className='mt-4'>
              <div>
                <label className='font-medium'>Number of overtime hours:</label>
              </div>
              <div>
                <input
                  type='number'
                  step='any'
                  name='overtime-amount'
                  className='w-full border border-solid border-gray-300 p-2 rounded'
                  defaultValue={fieldValues['overtime-amount']}
                  onChange={(evt) => {
                    setFieldValues((prevFieldValues) => ({
                      ...prevFieldValues,
                      'overtime-amount': evt.target.value,
                    }))
                  }}
                  onKeyPress={(evt) => {
                    if (evt.key === 'Enter') {
                      submitCalculator()
                    }
                  }}
                  onFocus={(evt) => scrollIntoView(evt.target)}
                />
              </div>
            </div>

            {/* <!-- CONTRACT WEEKLY STIPENDS --> */}
            <div className='mt-4'>
              <div>
                <label className='font-medium'>Contract Listed Weekly Stipends:</label>
              </div>
              <div>
                <input
                  type='number'
                  step='.01'
                  name='contracted-weekly-stipends'
                  className='w-full border border-solid border-gray-300 p-2 rounded'
                  defaultValue={fieldValues['contracted-weekly-stipends']}
                  onChange={(evt) => {
                    setFieldValues((prevFieldValues) => ({
                      ...prevFieldValues,
                      'contracted-weekly-stipends': evt.target.value,
                    }))
                  }}
                  onKeyPress={(evt) => {
                    if (evt.key === 'Enter') {
                      submitCalculator()
                    }
                  }}
                  onFocus={(evt) => scrollIntoView(evt.target)}
                />
              </div>
            </div>

            {/* <!-- STATE --> */}
            <div className='mt-4'>
              <div>
                <label className='font-medium'>State:</label>
              </div>
              <div>
                <select
                  name='state'
                  className='w-full p-2 rounded border border-solid border-gray-300'
                  defaultValue={fieldValues['state']}
                  onChange={(evt) => {
                    setFieldValues((prevFieldValues) => ({
                      ...prevFieldValues,
                      state: evt.target.value as USStates,
                    }))
                  }}>
                  {Object.entries(USStates).map((state) => (
                    <option key={state[1]} value={state[1]}>
                      {startCase(camelCase(state[0]))}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* <!-- CITY --> */}
            <div className='mt-4'>
              <div>
                <label className='font-medium'>City:</label>
              </div>
              <div>
                <input
                  name='city'
                  className='w-full border border-solid border-gray-300 p-2 rounded'
                  defaultValue={fieldValues['city']}
                  onChange={(evt) => {
                    setFieldValues((prevFieldValues) => ({
                      ...prevFieldValues,
                      city: evt.target.value,
                    }))
                  }}
                  onKeyPress={(evt) => {
                    if (evt.key === 'Enter') {
                      submitCalculator()
                    }
                  }}
                  onFocus={(evt) => scrollIntoView(evt.target)}
                  required
                />
              </div>
              {validationErrors['city'] && (
                <div className='validation-error text-red-500' tabIndex={-1}>
                  This field is required.
                </div>
              )}
            </div>

            {submitErrorMessage.length > 0 && (
              <div className='mt-4 text-red-500' dangerouslySetInnerHTML={{ __html: submitErrorMessage }}></div>
            )}

            {/* <!-- SUBMIT --> */}
            <button
              className='mt-8 w-full font-semibold bg-dark-blue rounded text-white py-4'
              onClick={() => submitCalculator()}>
              CALCULATE
            </button>

            {showCalculation && (
              <div className='mt-12'>
                <div className='sm:flex justify-content items-center mb-4'>
                  <div className='text-center sm:w-1/2 border-t-2 border-l-2 border-r-2 sm:border-b-2 border-dark-blue p-6'>
                    <h2 className='text-2xl mb-2'>Contract Says...</h2>
                    <div>Weekly Taxable: ${calculation?.weeklyTaxable}</div>
                    <div>
                      Weekly Stipends: $
                      {fieldValues['contracted-weekly-stipends'] ? fieldValues['contracted-weekly-stipends'] : 0}
                    </div>
                    <div>
                      Weekly Total Compensation:{' '}
                      <b>
                        $
                        {calculation &&
                          calculation.weeklyTaxable +
                            (fieldValues['contracted-weekly-stipends']
                              ? Number(fieldValues['contracted-weekly-stipends'])
                              : 0)}
                      </b>
                    </div>
                  </div>
                  <div className='text-center sm:w-1/2 border-2 border-dark-blue p-6'>
                    <h2 className='text-2xl mb-2'>GSA Max...</h2>
                    <div>Weekly Taxable: ${calculation?.weeklyTaxable}</div>
                    <div>
                      Weekly Stipends:{' '}
                      {calculation &&
                        (typeof calculation.expectedStipends.totalStipends !== 'number'
                          ? calculation.expectedStipends.totalStipends
                          : `$${calculation.expectedStipends.totalStipends}`)}
                    </div>
                    <div>
                      Weekly Total Compensation: <b>{calculation?.totalCompensation}</b>
                    </div>
                  </div>
                </div>
                <div className='text-center border-2 border-dark-blue p-6'>
                  <h3 className='text-xl mb-2'>Expected Stipends Breakdown</h3>
                  <h4 className='text-lg mb-1'>
                    GSA max rates for {startCase(camelCase(getObjectKeyFromValue(USStates, fieldValues.state)))} in{' '}
                    {months[fieldValues['start-month'] - 1]} are
                  </h4>
                  <div>
                    Lodging:{' '}
                    {calculation &&
                      (typeof calculation.expectedStipends.lodgingRatePerDay !== 'number'
                        ? Number(calculation.expectedStipends.lodgingRatePerDay) * 7
                        : `$${calculation.expectedStipends.lodgingRatePerDay * 7} / week`)}
                  </div>
                  <div>
                    Meals:{' '}
                    {calculation &&
                      (typeof calculation.expectedStipends.mealsRatePerDay !== 'number'
                        ? Number(calculation.expectedStipends.mealsRatePerDay) * 7
                        : `$${calculation.expectedStipends.mealsRatePerDay * 7} / week`)}
                  </div>
                </div>
              </div>
            )}
            <div className='calculator-focus-trap' tabIndex={-1}></div>
          </div>
        )}
      </div>

      <div className='mt-12'>
        <Footer />
      </div>

      <BottomNav />
    </div>
  )
}

export default IncomeCalc
