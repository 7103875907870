import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { checkIsUpTree, scrollIntoView } from '../utils'

import { Agency } from '../models/Agency.model'

import { AgencyService } from '../services/AgencyService/AgencyService'

import { AgencyFiveFaceRating } from '../components/AgencyFiveFaceRating'
import { ErrorView } from '../components/Error'
import { Footer } from '../components/Footer'
import { Loading } from '../components/Loading'
import { Paging } from '../components/Paging'
import { TopLogo } from '../components/TopLogo'
import { BottomNav } from '../components/BottomNav'

//-----------------------------------------------------------------------------
// SUB COMPONENTS
//-----------------------------------------------------------------------------

const HeroSection = () => {
  return (
    <>
      {/* Mobile View */}
      <div className='md:hidden'>
        <div className='m-auto' style={{ maxWidth: '90vw' }}>
          <h2 className='font-semibold mt-4 text-center text-4xl leading-normal'>Discover Agencies</h2>
        </div>
        <img src='/assets/scrubstr-agency.png' alt='Agency Header' className='max-h-80 m-auto' />
      </div>
      {/* Desktop View */}
      <div
        style={{ backgroundImage: `url(/assets/scrubstr-usa-wide.png)` }}
        className='hidden md:block py-40 bg-cover bg-center bg-dark-blue text-white'>
        <div className='m-auto' style={{ maxWidth: '90vw' }}>
          <h2 className='font-semibold text-shadow mt-4 text-center text-5xl leading-normal'>Discover Agencies</h2>
          <h3 className='font-semibold text-shadow mt-4 text-center text-2xl leading-normal'>
            Search our database of 70+ US Agencies
          </h3>
        </div>
      </div>
    </>
  )
}

type FilterProps = {
  currentSearchTerm: string
  setNewSearchTerm: (newTerm: string) => void
  setAutoCompleteTerm: React.Dispatch<React.SetStateAction<string>>
  autoCompleteResults: Agency[]
}
const Filter = ({ currentSearchTerm, setNewSearchTerm, setAutoCompleteTerm, autoCompleteResults }: FilterProps) => {
  const [termFocused, setTermFocused] = useState(false)
  const [tempSearchTerm, setTempSearchTerm] = useState(currentSearchTerm)
  const autoCompleteContainerRef = useRef<HTMLDivElement>(null)

  return (
    <div className='lg:flex justify-center'>
      <div className='flex-auto'>
        <input
          value={tempSearchTerm}
          onChange={(evt) => setTempSearchTerm(evt.target.value)}
          onKeyUp={(evt) => {
            if (evt.key === 'Enter') {
              setNewSearchTerm(tempSearchTerm)
              setTermFocused(false)
            } else {
              setAutoCompleteTerm(tempSearchTerm)
              setTermFocused(true)
            }
          }}
          onFocus={(evt) => {
            scrollIntoView(evt.target)
          }}
          onBlur={(evt) => {
            if (!checkIsUpTree(evt.relatedTarget, autoCompleteContainerRef.current)) {
              setTermFocused(false)
            }
          }}
          className='px-4 py-4 rounded-sm border border-solid border-gray-300 w-full'
          placeholder='Agency Name'
        />
        <div className='relative'>
          {autoCompleteResults.length > 0 && termFocused && (
            <div className='absolute bg-white left-0 right-0 shadow-2xl' ref={autoCompleteContainerRef}>
              {autoCompleteResults.map((agency) => (
                <Link
                  key={agency.id}
                  className='border-b p-2 block w-full'
                  to={`/agencies/${agency.permalink}`}
                  dangerouslySetInnerHTML={{ __html: agency.name }}
                  tabIndex={0}></Link>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className='mt-4 lg:mt-0'>
        <button
          className='px-4 py-4 rounded-sm text-white font-semibold uppercase w-full'
          onClick={() => {
            setNewSearchTerm(tempSearchTerm)
          }}
          style={{ background: '#303c6c' }}>
          Search
        </button>
      </div>
    </div>
  )
}

type SortProps = {
  sortValue: string
  setSortValue: (sortValue: string) => void
  totalResults: number
  numberShowing: number
  page: number
}
const Sort = ({ sortValue, setSortValue, totalResults, numberShowing, page }: SortProps) => (
  <div className='mt-12 lg:flex lg:justify-between'>
    <div>
      <label className='lg:flex lg:gap-3'>
        <div className='lg:self-center'>Sort:</div>
        <div>
          <select
            value={sortValue}
            onChange={(evt) => setSortValue(evt.target.value)}
            className='px-4 py-4 lg:p-2 rounded-sm border border-solid border-gray-300 w-full lg:bg-white'>
            {Object.entries({
              highRating: 'Top Rated',
              lowRating: 'Lowest Rated',
              az: 'A - Z',
              za: 'Z - A',
            }).map((x) => (
              <option key={x[0]} value={x[0]}>
                {x[1]}
              </option>
            ))}
          </select>
        </div>
      </label>
    </div>

    {totalResults > 0 && (
      <div className='mt-4 lg:mt-0 lg:self-center text-gray-600'>
        {(page - 1) * 12 + (numberShowing > 0 ? 1 : 0)}-{(page - 1) * 12 + numberShowing} of {totalResults} Results
      </div>
    )}
  </div>
)

type RatingProps = {
  score: number
}
const Rating = ({ score }: RatingProps) => <div className='text-3xl text-gray-600'>{score} / 5</div>

// TODO: add link for agency reviews subsection of agency detail page on read reviews (current site uses hashtag scroll)
const AgencyListItem = (agency: Agency) => (
  <li
    key={agency.id}
    className='text-center border border-solid border-gray-300 rounded-sm px-2 py-8 lg:py-0 lg:flex lg:justify-between lg:gap-6'>
    <div className='lg:w-48 lg:h-48 lg:min-w-48'>
      <Link className='block lg:w-full lg:h-full lg:relative' to={`/agencies/${agency.permalink}`}>
        <img
          className='mx-auto max-h-40 lg:max-h-full lg:my-auto lg:absolute lg:top-1/2 lg:bottom-1/2'
          src={agency.image ? agency.image : undefined}
          alt={`${agency.name}`}
        />
      </Link>
    </div>

    <div className='lg:text-left lg:flex-auto lg:py-2'>
      <div className='mt-4'>
        <Link className='text-orange text-3xl' to={`/agencies/${agency.permalink}`}>
          {agency.name}
        </Link>
      </div>

      <div className='mt-4 text-gray-500'>
        {agency.website && (
          <div>
            <a
              href={`${!agency.website.startsWith('http') ? 'http://' : ''}${agency.website}`}
              target='_blank'
              rel='noreferrer'
              className='text-orange'>
              {agency.website}
            </a>
          </div>
        )}
        {agency.phone && (
          <div>
            <a href={`tel:${agency.phone.replace(/\D/g, '')}`} target='_blank' rel='noreferrer' className='text-orange'>
              {agency.phone}
            </a>
          </div>
        )}
        {agency.facebook && (
          <div>
            <a
              href={`${!agency.facebook.startsWith('http') ? 'http://' : ''}${agency.facebook}`}
              target='_blank'
              rel='noreferrer'
              className='text-orange'>
              Facebook
            </a>
          </div>
        )}
        <div>{agency.address}</div>
      </div>

      {agency.rating > 0 && (
        <div className='mt-4'>
          <Link className='text-orange' to={`/agencies/${agency.permalink}`}>
            Read Reviews
          </Link>
        </div>
      )}
    </div>

    <div>
      <div className='mt-4'>
        <AgencyFiveFaceRating agencyID={agency.id} rating={agency.rating} />
      </div>

      {agency.rating > 0 && (
        <div className='mt-4'>
          <Rating score={agency.rating} />
          <div className='text-xs text-gray-500'>
            {agency.reviewCount} review{agency.reviewCount && agency.reviewCount > 1 ? 's' : ''}
          </div>
        </div>
      )}

      <div className='lg:flex lg:gap-3'>
        <div className='mt-4'>
          <Link
            className='bg-orange block w-full font-semibold text-md rounded px-4 py-2 text-white whitespace-nowrap'
            to={`/review-agency/${agency.id}`}>
            Review It
          </Link>
        </div>

        <div className='mt-4'>
          <Link
            className='bg-dark-blue block w-full font-semibold text-md rounded px-4 py-2 text-white whitespace-nowrap'
            to={`/agencies/${agency.permalink}`}>
            Learn More
          </Link>
        </div>
      </div>
    </div>
  </li>
)

//-----------------------------------------------------------------------------
// MAIN COMPONENT
//-----------------------------------------------------------------------------

const Agencies: React.FC = () => {
  const [agencyResults, setAgencyResults] = useState<Agency[]>([])
  const [totalResults, setTotalResults] = useState(0)
  const [numberShowing, setNumberShowing] = useState(0)
  const [initialLoad, setInitialLoad] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [searchErrorMessage, setSearchErrorMessage] = useState('')
  const [term, setTerm] = useState('')
  const [autoCompleteTerm, setAutoCompleteTerm] = useState('')
  const [autoCompleteResults, setAutoCompleteResults] = useState<Agency[]>([])
  const [sortValue, setSortValue] = useState('highRating')
  const searchResultContainerRef = useRef<HTMLDivElement>(null)

  const agencyService = new AgencyService()

  const setSortAndUpdatePage = (sortValue: string) => {
    setSortValue(sortValue)
    setPage(1)
  }

  const setTermAndUpdatePage = (newTerm: string) => {
    setTerm(newTerm)
    setPage(1)
  }

  useEffect(() => {
    setIsLoading(true)
    setSearchErrorMessage('')
    agencyService
      .search({ page, term, sort: sortValue })
      .then((results) => {
        setIsLoading(false)
        setInitialLoad(false)
        setAgencyResults(results.agencies)
        setTotalResults(results.totalResults)
        setNumberShowing(results.agencies.length)
        setPage(results.currentPage)
        setTotalPages(results.totalPages)
        if (!initialLoad) {
          scrollToResults()
        }
      })
      .catch((err) => {
        setSearchErrorMessage(err)
        console.error(err)
        setIsLoading(false)
        scrollToResults()
      })
  }, [page, sortValue, term])

  useEffect(() => {
    if (!initialLoad) {
      agencyService
        .search({ page: 1, term: autoCompleteTerm, sort: 'az', autocomplete: true })
        .then((results) => {
          setAutoCompleteResults(results.agencies)
        })
        .catch(() => {
          // likely just a cancel of a previous request
        })
    } else {
      setAutoCompleteResults([])
    }
  }, [autoCompleteTerm])

  const scrollToResults = () => {
    if (searchResultContainerRef && searchResultContainerRef.current)
      scrollIntoView(searchResultContainerRef.current, 0)
  }

  return (
    <div id='page-root' className='h-screen overflow-auto relative'>
      <TopLogo />

      <HeroSection />

      <div className='px-4 md:pt-20 max-w-xl lg:max-w-6xl mx-auto relative'>
        <Filter
          currentSearchTerm={term}
          setNewSearchTerm={setTermAndUpdatePage}
          setAutoCompleteTerm={setAutoCompleteTerm}
          autoCompleteResults={autoCompleteResults}
        />

        <div className='search-results' ref={searchResultContainerRef}>
          <Sort
            totalResults={totalResults}
            sortValue={sortValue}
            setSortValue={setSortAndUpdatePage}
            numberShowing={numberShowing}
            page={page}
          />

          {searchErrorMessage.length > 0 && <ErrorView>{searchErrorMessage}</ErrorView>}

          {!(agencyResults.length > 0) && (
            <div className='text-center my-12 lg:mt-4'>
              <div className='mb-2'>No agencies found matching your search.</div>
              <a
                href='https://scrubstr.com/add-agency-request/'
                target='_blank'
                rel='noreferrer'
                className='block px-4 py-4 rounded-sm text-white font-semibold uppercase'
                style={{ background: '#303c6c' }}>
                Missing Agency?
              </a>
            </div>
          )}
          {agencyResults.length > 0 && (
            <div className='my-12 lg:mt-4'>
              <ul className='space-y-8'>{agencyResults.map(AgencyListItem)}</ul>
            </div>
          )}
        </div>

        <Paging totalPages={totalPages} currentPage={page} setPage={setPage} scrollToResults={scrollToResults} />

        {isLoading && <Loading />}
      </div>

      <div className='mt-12'>
        <Footer />
      </div>
      <BottomNav />
    </div>
  )
}

export default Agencies
