import axios, { CancelTokenSource } from 'axios'

import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { Agency } from '../../models/Agency.model'
import { AgencyDTO } from '../../models/AgencyDTO.model'

import ScrubstrApiQueryService, {
  ScrubstrApiQueryServiceGetEvent,
} from '../ScrubstrApiQueryService/ScrubstrApiQueryService'

export class AgencyService {
  private autocompleteAgenciesSource: CancelTokenSource | null = null

  /**
   * @description Search for a single agency by id or permalink
   *
   * @param id
   * @param permalink
   *
   * @returns promise for a Agency
   */
  public async searchOne({ id, permalink }: { id?: number; permalink?: string }): Promise<Agency> {
    const params = {
      id,
      permalink,
    }
    const scrubstrApiQueryRequest: ScrubstrApiQueryServiceGetEvent = {
      endpoint: `agency/search-one`,
      params,
    }

    const scrubstrApiQueryService = new ScrubstrApiQueryService()
    const agencyRaw = await scrubstrApiQueryService.get<AgencyDTO>(scrubstrApiQueryRequest)
    const agency: Agency = {
      id: agencyRaw.id,
      image: agencyRaw.imageURL,
      name: agencyRaw.name,
      address: agencyRaw.address,
      rating: agencyRaw.averageRating,
      permalink: agencyRaw.permalink,
      website: agencyRaw.website,
      facebook: agencyRaw.facebook,
      phone: agencyRaw.phone,
      email: agencyRaw.email,
      reviews: agencyRaw.reviews,
      reviewCount: agencyRaw.reviewCount,
      ratingsSummary: agencyRaw.ratingsSummary,
      updatedAt: agencyRaw.updatedAt,
      createdAt: agencyRaw.createdAt,
    }
    return agency
  }

  public async search({
    page,
    pageSize,
    term,
    sort,
    autocomplete,
  }: {
    page?: number
    pageSize?: number
    term: string
    sort: string
    autocomplete?: boolean
  }): Promise<{
    agencies: Agency[]
    totalResults: number
    totalPages: number
    currentPage: number
  }> {
    const params: {
      page?: number
      pageSize?: number
      name?: string
      orderBy?: string
      orderDirection?: string
      orderAToZTies?: string
      excludeReviews?: string
    } = {
      page,
      pageSize,
      name: term || undefined,
      excludeReviews: autocomplete ? 'true' : undefined,
    }

    if (sort) {
      switch (sort) {
        case 'highRating':
          params.orderBy = 'averageRating'
          params.orderDirection = 'DESC'
          params.orderAToZTies = 'true'
          break
        case 'lowRating':
          params.orderBy = 'averageRating'
          params.orderDirection = 'ASC'
          params.orderAToZTies = 'true'
          break
        case 'az':
          params.orderBy = 'name'
          params.orderDirection = 'ASC'
          break
        case 'za':
          params.orderBy = 'name'
          params.orderDirection = 'DESC'
          break
        default:
          break
      }
    }

    const scrubstrApiQueryRequest: ScrubstrApiQueryServiceGetEvent = {
      endpoint: 'agency',
      params,
    }

    if (autocomplete) {
      if (this.autocompleteAgenciesSource !== null) {
        this.autocompleteAgenciesSource.cancel('cancelToken triggered')
      }
      this.autocompleteAgenciesSource = axios.CancelToken.source()
      scrubstrApiQueryRequest.cancelToken = this.autocompleteAgenciesSource.token
    }

    const agencyResults: {
      agencies: Agency[]
      totalResults: number
      totalPages: number
      currentPage: number
    } = {
      agencies: [],
      totalResults: 0,
      totalPages: 0,
      currentPage: Number(page),
    }
    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      const agenciesRaw = await scrubstrApiQueryService.get<PaginatedResponse<AgencyDTO>>(scrubstrApiQueryRequest)
      // wrap to avoid errors if the request is cancelled
      if (agenciesRaw) {
        agencyResults.agencies = agenciesRaw.data.map((agency) => ({
          id: agency.id,
          image: agency.imageURL,
          name: agency.name,
          address: agency.address,
          website: agency.website,
          facebook: agency.facebook,
          phone: agency.phone,
          email: agency.email,
          rating: agency.averageRating,
          reviewCount: agency.reviewCount,
          permalink: agency.permalink,
          updatedAt: agency.updatedAt,
          createdAt: agency.createdAt,
        }))

        agencyResults.totalResults = agenciesRaw.total
        agencyResults.totalPages = Math.ceil(agenciesRaw.total / 12)
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return agencyResults
  }
}
