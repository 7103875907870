import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { isPlatform } from '@ionic/react'
import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import Auth0ProviderWithHistory from './providers/Auth0ProviderWithHistory'
import { AppUpdateProvider } from './providers/AppUpdateContext'
import { AppLoadingProvider } from './providers/AppLoadingContext'
import { AuthStateProvider } from './providers/AuthStateContext'
import './App.css'

import Login from './pages/Login'
import Agencies from './pages/Agencies'
import AgencyDetail from './pages/AgencyDetail'
import Hospitals from './pages/Hospitals'
import HospitalDetail from './pages/HospitalDetail'
import Review from './pages/Review'
import Tools from './pages/Tools'
import IncomeCalc from './pages/IncomeCalc'
import GSACalc from './pages/GSACalc'
import Profile from './pages/Profile'
import Register from './pages/Register'
import Logout from './pages/Logout'

const App: React.FC = () => {
  useEffect(() => {
    if (Capacitor.isPluginAvailable('Keyboard') && isPlatform('iphone')) {
      Keyboard.setAccessoryBarVisible({ isVisible: true })
    }
  }, [])

  return (
    <IonApp>
      <IonReactRouter>
        <Auth0ProviderWithHistory>
          <AppUpdateProvider>
            <AppLoadingProvider>
              <AuthStateProvider>
                <IonRouterOutlet>
                  <Route exact path='/redirect'>
                    <div>Redirecting...</div>
                  </Route>
                  <Route exact path='/profile'>
                    <Profile />
                  </Route>
                  <Route exact path='/login/redirect/:redirect'>
                    <Login />
                  </Route>
                  <Route exact path='/login'>
                    <Login />
                  </Route>
                  <Route exact path='/register'>
                    <Register />
                  </Route>
                  <Route exact path='/logout'>
                    <Logout />
                  </Route>
                  <Route path='/review-hospital'>
                    <Review reviewType='hospital' />
                  </Route>
                  <Route path='/review-hospital/:id'>
                    <Review reviewType='hospital' />
                  </Route>
                  <Route path='/review-agency'>
                    <Review reviewType='agency' />
                  </Route>
                  <Route path='/review-agency/:id'>
                    <Review reviewType='agency' />
                  </Route>
                  <Route exact path='/hospitals'>
                    <Hospitals />
                  </Route>
                  <Route exact path='/hospitals/:permalink' component={HospitalDetail} />
                  <Route exact path='/hospital'>
                    <Redirect to='/hospitals' />
                  </Route>
                  <Route exact path='/agencies'>
                    <Agencies />
                  </Route>
                  <Route exact path='/agencies/:permalink' component={AgencyDetail} />
                  <Route exact path='/agency'>
                    <Redirect to='/agencies' />
                  </Route>
                  <Route exact path='/'>
                    <Redirect to='/hospitals' />
                  </Route>
                  <Route exact path='/tools'>
                    <Tools />
                  </Route>
                  <Route exact path='/income-calculator'>
                    <IncomeCalc />
                  </Route>
                  <Route exact path='/gsa-calculator'>
                    <GSACalc />
                  </Route>
                  {/* Default route to / */}
                  <Route>
                    <Redirect to='/' />
                  </Route>
                </IonRouterOutlet>
              </AuthStateProvider>
            </AppLoadingProvider>
          </AppUpdateProvider>
        </Auth0ProviderWithHistory>
      </IonReactRouter>
    </IonApp>
  )
}

export default App
