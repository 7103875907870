import React from 'react'

type PagingProps = {
  totalPages: number
  currentPage: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  scrollToResults: () => unknown
}
export const Paging = ({ totalPages, currentPage, setPage, scrollToResults }: PagingProps): JSX.Element => {
  const setPageAndScroll = (page: number) => () => {
    if (page <= 0) return
    if (page > totalPages) return

    setPage(page)
    scrollToResults()
  }

  return (
    <div>
      {totalPages > 1 && (
        <div className='flex justify-between lg:justify-center'>
          <div className='text-orange cursor-pointer' onClick={setPageAndScroll(currentPage - 1)}>
            Prev
          </div>

          <div className='w-full lg:w-auto mx-8'>
            <ul className='text-orange flex justify-between lg:justify-center w-full lg:w-auto lg:gap-4'>
              <li
                onClick={setPageAndScroll(1)}
                className={currentPage === 1 ? 'font-bold text-gray-800 cursor-pointer' : 'cursor-pointer'}>
                1
              </li>

              {currentPage > 4 && <li className='text-gray-800'>...</li>}

              {currentPage > 3 && (
                <li className='cursor-pointer' onClick={setPageAndScroll(currentPage - 2)}>
                  {currentPage - 2}
                </li>
              )}
              {currentPage > 2 && (
                <li className='cursor-pointer' onClick={setPageAndScroll(currentPage - 1)}>
                  {currentPage - 1}
                </li>
              )}
              {currentPage > 1 && <li className='font-bold text-gray-800 cursor-pointer'>{currentPage}</li>}
              {currentPage < totalPages - 3 && (
                <li className='cursor-pointer' onClick={setPageAndScroll(currentPage + 1)}>
                  {currentPage + 1}
                </li>
              )}
              {currentPage < totalPages - 2 && (
                <li className='cursor-pointer' onClick={setPageAndScroll(currentPage + 2)}>
                  {currentPage + 2}
                </li>
              )}

              {currentPage < totalPages - 3 && <li className='text-gray-800'>...</li>}

              {currentPage < totalPages && (
                <li className='cursor-pointer' onClick={setPageAndScroll(totalPages)}>
                  {totalPages}
                </li>
              )}
            </ul>
          </div>

          <div className='text-orange cursor-pointer' onClick={setPageAndScroll(currentPage + 1)}>
            Next
          </div>
        </div>
      )}
    </div>
  )
}
