import React, { useEffect } from 'react'
import { IonAlert, isPlatform } from '@ionic/react'
import { appStoreSources } from '../models/AppStoreSources.model'
import { Browser } from '@capacitor/browser'

export const AppUpdatePrompter = () => {
  const isMobileDevice = isPlatform('android') || isPlatform('ios')
  let countdown = 20
  const alertContent = () => {
    let msg = '<span>Please update your app to the latest version.</span>'
    let btnConfirm = 'Update'
    if (!isMobileDevice) {
      msg = `<span>Please refresh your app to fetch the latest version.</span> <span> This page will auto-refresh in <span id="update-prompt-countdown">${countdown}</span> seconds.</span>`
      btnConfirm = 'Refresh'
    }
    return {
      msg: msg,
      btnConfirm: btnConfirm,
    }
  }

  const { msg, btnConfirm } = alertContent()

  let reloadInterval: NodeJS.Timeout

  // handle app outdated logic on UseEffect
  useEffect(() => {
    // if the platform is web, setup a countdown timer to show on the update prompt. After 20 seconds, automatically refresh the page
    if (!isMobileDevice) {
      reloadInterval = setInterval(() => {
        countdown -= 1
        const countdownEl = document.getElementById('update-prompt-countdown')
        if (countdownEl) {
          countdownEl.innerHTML = countdown.toString()
        }
        if (countdown <= 0) {
          window.location.reload()
        }
      }, 1000)
    }
  }, [])

  return (
    <IonAlert
      isOpen={true}
      header='App Update Required'
      message={msg}
      backdropDismiss={false}
      onDidDismiss={() => {
        if (!isMobileDevice) {
          // cancel the interval
          clearInterval(reloadInterval)
        }
      }}
      buttons={[
        {
          text: btnConfirm,
          handler: () => {
            if (isPlatform('android')) {
              // Redirect using Capacitor's Browser plugin
              Browser.open({
                url: appStoreSources.android,
              })
            } else if (isPlatform('ios')) {
              // Redirect using Capacitor's Browser plugin
              Browser.open({
                url: appStoreSources.ios,
              })
            } else {
              // web platform so just refresh the page
              window.location.reload()
            }
            // Keep the alert open after clicking the button
            return false
          },
        },
        // cancel button that closes the alert
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-red-500',
        },
      ]}
    />
  )
}
