import { IncomeCalcDTO, Month } from '../models/IncomeCalcDTO.model'

type GetTotalStipendsProps = {
  mealsRatePerDay: number | string
  lodgingRatePerDay: number | string
  numberOfDays: number
}
const getTotalStipends = ({ mealsRatePerDay, lodgingRatePerDay, numberOfDays }: GetTotalStipendsProps) => {
  if (typeof mealsRatePerDay !== 'number') return 'Sorry. Unable to calculate total stipends'
  if (typeof lodgingRatePerDay !== 'number') return 'Sorry. Unable to calculate total stipends'

  return (mealsRatePerDay + lodgingRatePerDay) * numberOfDays
}

type Stipends = {
  totalStipends: number | string
  mealsRatePerDay: number | string
  lodgingRatePerDay: number | string
}
type ComputeStipendsProps = {
  GSAResult: IncomeCalcDTO
  startMonth: number
  numberOfDays: number
}
const computeStipends = ({ GSAResult, startMonth, numberOfDays }: ComputeStipendsProps) => {
  try {
    const monthFound =
      typeof GSAResult.rates?.[0]?.rate?.[0]?.months?.month !== 'undefined'
        ? GSAResult.rates[0].rate[0].months.month
        : [{ value: -1, number: 0, short: '', long: '' } as Month]

    const month = monthFound.filter(function (month: Month) {
      return month.number === startMonth
    })[0]

    const mealsRatePerDay =
      typeof GSAResult.rates?.[0]?.rate?.[0]?.meals !== 'undefined'
        ? GSAResult.rates[0].rate[0].meals
        : '(No meals rate found)'
    const lodgingRatePerDay = typeof month?.value !== 'undefined' ? month.value : '(No lodging rate found)'

    const totalStipends = getTotalStipends({ mealsRatePerDay, lodgingRatePerDay, numberOfDays })

    return {
      totalStipends: totalStipends,
      mealsRatePerDay: mealsRatePerDay,
      lodgingRatePerDay: lodgingRatePerDay,
    } as Stipends
  } catch (err) {
    console.error(err)

    return {
      totalStipends: 'Sorry. Unable to calculate total stipends.',
      mealsRatePerDay: 'Sorry. Unable to determine meals rate per day.',
      lodgingRatePerDay: 'Sorry. Unable to determine lodging rate per day.',
    }
  }
}

type ComputeResultsProps = {
  hourlyRate: number
  hourAmount: number
  overtimeRate: number
  overtimeAmount: number
  startMonth: number
  numberOfDays: number
  GSAResult: IncomeCalcDTO
}
const computeResults = ({
  hourlyRate,
  hourAmount,
  overtimeRate,
  overtimeAmount,
  startMonth,
  numberOfDays,
  GSAResult,
}: ComputeResultsProps) => {
  const hourlyPay = hourlyRate * hourAmount
  const overtimePay = overtimeRate * overtimeAmount
  const weeklyTaxable = hourlyPay + overtimePay

  const expectedStipends = computeStipends({ GSAResult, startMonth, numberOfDays })

  return {
    weeklyTaxable,
    expectedStipends,
  }
}

type GetTotalCompensationProps = {
  weeklyTaxable: number
  totalStipends: number | string
}
const getTotalCompensation = ({ weeklyTaxable, totalStipends }: GetTotalCompensationProps) => {
  if (typeof weeklyTaxable !== 'number') return 'Unable to calculate total'
  if (typeof totalStipends !== 'number') return 'Unable to calculate total'

  return '$' + (weeklyTaxable + totalStipends)
}

export type Compensation = {
  weeklyTaxable: number
  expectedStipends: Stipends
  totalCompensation: string
}

type CalculateCompensationProps = {
  hourlyRate: number
  hourAmount: number
  overtimeRate: number
  overtimeAmount: number
  startMonth: number
  numberOfDays: number
  GSAResult: IncomeCalcDTO
}
export const calculateCompensation = ({
  hourlyRate,
  hourAmount,
  overtimeRate,
  overtimeAmount,
  startMonth,
  numberOfDays,
  GSAResult,
}: CalculateCompensationProps): Compensation => {
  hourlyRate = hourlyRate > 0 ? hourlyRate : 0
  hourAmount = hourAmount > 0 ? hourAmount : 0
  overtimeRate = overtimeRate > 0 ? overtimeRate : 0
  overtimeAmount = overtimeAmount > 0 ? overtimeAmount : 0
  numberOfDays = numberOfDays > 0 ? numberOfDays : 0

  const results = computeResults({
    hourlyRate,
    hourAmount,
    overtimeRate,
    overtimeAmount,
    startMonth,
    numberOfDays,
    GSAResult,
  })

  const weeklyTaxable = results.weeklyTaxable
  const expectedStipends = results.expectedStipends
  const totalStipends = expectedStipends.totalStipends
  const totalCompensation = getTotalCompensation({ weeklyTaxable, totalStipends })

  return {
    weeklyTaxable,
    expectedStipends,
    totalCompensation,
  }
}
